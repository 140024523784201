import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import {
  getActivityJSONByCourseIdActivityID,
  // getLessonById,
} from '../../services/api.js';
import ReactMarkdown from 'react-markdown';
import {
  handleCourseNextActivity,
  handleCoursePreviousActivity,
} from '../../helpers/componentsHelper.js';

const LessonViewNew = ({ courseId, sectionId, activityType, activityId }) => {
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const token = localStorage.getItem('token');
        const activityResponse = await getActivityJSONByCourseIdActivityID(
          token,
          courseId,
          sectionId,
          activityType,
          activityId
        );
        console.log(
          `activityResponse: ${JSON.stringify(activityResponse, null, 4)}`
        );
        setLesson(activityResponse.data.activity);
      } catch (error) {
        console.error(
          'Error fetching lesson:',
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchLesson();
  }, [activityId, courseId, sectionId, activityType]);

  if (!lesson) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-80">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="d-flex flex-column">
      <Row className="flex-grow-1">
        <Col className="h-80">
          <Card className="h-80 border-0">
            <Card.Body>
              <Card.Title as="h2">{lesson.title}</Card.Title>
              <ReactMarkdown>{lesson.description}</ReactMarkdown>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Button
            variant="secondary"
            onClick={() =>
              handleCoursePreviousActivity(
                navigate,
                courseId,
                sectionId,
                activityType,
                activityId
              )
            }
          >
            Previous
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            onClick={() =>
              handleCourseNextActivity(
                navigate,
                courseId,
                sectionId,
                activityType,
                activityId
              )
            }
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

// PropTypes validation for the lessonId prop
LessonViewNew.propTypes = {
  courseId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  activityType: PropTypes.number.isRequired,
  activityId: PropTypes.number.isRequired,
};

export default LessonViewNew;
