import ProblemStatementFileDataLoader from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/ProblemStatementFileDataLoader.js';

const loadingProblemStatementData = (fileName) => {
  const ProblemStatementLoader = (props) => (
    <ProblemStatementFileDataLoader fileName={fileName} {...props} />
  );

  // Set display name explicitly
  ProblemStatementLoader.displayName = 'ProblemStatementLoader';

  return ProblemStatementLoader;
};

export const mathsEasyProblemStatements = (title, setComponent) => {
  console.log(`REACHED: mathsEasyProblemStatements`);
  if (title === 'Excel Sheet Column Title') {
    const fileName = '168ExcelSheetColumnTitleMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Roman to Integer') {
    const fileName = '13RomantoIntegerMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Missing Number') {
    const fileName = '268MissingNumberMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Similar RGB Color') {
    const fileName = '800SimilarRGBColorMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Maximum Product of Three Numbers') {
    const fileName = '628MaximumProductofThreeNumbersMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Sqrt x ') {
    const fileName = '69SqrtxMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Palindrome Number') {
    const fileName = '9PalindromeNumberMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Power of Four') {
    const fileName = '342PowerofFourMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Power of Three') {
    const fileName = '326PowerofThreeMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Self Dividing Numbers') {
    const fileName = '728SelfDividingNumbersMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Projection Area of 3D Shapes') {
    const fileName = '883ProjectionAreaof3DShapesMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Add Binary') {
    const fileName = '67AddBinaryMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Add to Array Form of Integer') {
    const fileName = '989AddtoArrayFormofIntegerMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Nim Game') {
    const fileName = '292NimGameMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Excel Sheet Column Number') {
    const fileName = '171ExcelSheetColumnNumberMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Happy Number') {
    const fileName = '202HappyNumberMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Valid Perfect Square') {
    const fileName = '367ValidPerfectSquareMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Perfect Number') {
    const fileName = '507PerfectNumberMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Prime Number of Set Bits in Binary Representation') {
    const fileName =
      '762PrimeNumberofSetBitsinBinaryRepresentationMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Add Digits') {
    const fileName = '258AddDigitsMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Base 7') {
    const fileName = '504Base7MathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Surface Area of 3D Shapes') {
    const fileName = '892SurfaceAreaof3DShapesMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Fizz Buzz') {
    const fileName = '412FizzBuzzMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Arranging Coins') {
    const fileName = '441ArrangingCoinsMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'X of a Kind in a Deck of Cards') {
    const fileName = '914XofaKindinaDeckofCardsMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Convert a Number to Hexadecimal') {
    const fileName = '405ConvertaNumbertoHexadecimalMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Smallest Range I') {
    const fileName = '908SmallestRangeIMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Power of Two') {
    const fileName = '231PowerofTwoMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Largest Perimeter Triangle') {
    const fileName = '976LargestPerimeterTriangleMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Rectangle Overlap') {
    const fileName = '836RectangleOverlapMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Fibonacci Number') {
    const fileName = '509FibonacciNumberMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Ugly Number') {
    const fileName = '263UglyNumberMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Divisor Game') {
    const fileName = '1025DivisorGameMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Construct the Rectangle') {
    const fileName = '492ConstructtheRectangleMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Range Addition II') {
    const fileName = '598RangeAdditionIIMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Plus One') {
    const fileName = '66PlusOneMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Climbing Stairs') {
    const fileName = '70ClimbingStairsMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Largest Triangle Area') {
    const fileName = '812LargestTriangleAreaMathsEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  }
};
