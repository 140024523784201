import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { careerPathsDetails } from '../../data/careerPathDetails.js';
import Navbar from '../Navbar.js';
import Footer from '../Footer.js';
import LoadPDF from '../LoadPDF.js';
import './CareerPathDetails.css';

const CareerPathDetails = () => {
  const { coursePath } = useParams();
  const courseData = careerPathsDetails[coursePath];
  const cardRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!courseData) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.2 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, [courseData]);

  if (!courseData) {
    return <h2 className="text-center mt-5">Course not found</h2>;
  }

  const handleEnrollClick = () => {
    navigate('/contact');
  };

  return (
    <>
      {/* Metadata for SEO */}
      <Helmet>
        <title>{`${courseData.title} - Comprehensive Career Path`}</title>
        <meta
          name="description"
          content={`Learn about ${courseData.title}. Explore career development opportunities, program highlights, and master essential skills with our comprehensive guide.`}
        />
        <meta
          name="keywords"
          content={`${courseData.title}, career path, skill development, professional certification, career support`}
        />
        <meta name="author" content="Happy Learning Solutions" />
      </Helmet>

      {/* Page Content */}
      <div className="d-flex flex-column min-vh-100">
        <header>
          <Navbar />
        </header>

        <main>
          <Container className="my-5">
            {/* Hero Section */}
            <section aria-labelledby="course-intro">
              <Row className="align-items-center mb-5">
                <Col lg={6}>
                  <Card
                    className="zoom-card"
                    ref={(el) => (cardRefs.current[0] = el)}
                  >
                    <Card.Body>
                      <h1 id="course-intro" className="text-center mb-4">
                        {courseData.title}
                      </h1>
                      <p className="lead text-center">
                        {courseData.description}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card
                    className="zoom-card"
                    ref={(el) => (cardRefs.current[1] = el)}
                  >
                    <Card.Img
                      variant="top"
                      src={courseData.image}
                      alt={`${courseData.title} career path`}
                      className="img-fluid rounded"
                    />
                  </Card>
                </Col>
              </Row>
            </section>

            {/* Program Highlights */}
            <section aria-labelledby="program-highlights">
              <Card
                className="zoom-card mb-5"
                ref={(el) => (cardRefs.current[2] = el)}
              >
                <Card.Body>
                  <h2 id="program-highlights" className="mb-3">
                    Program Highlights
                  </h2>
                  <ul>
                    {courseData.highlights.map((highlight, index) => (
                      <li key={index}>{highlight}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </section>

            {/* Skills Section */}
            <section aria-labelledby="skills-master">
              <Card
                className="zoom-card mb-5"
                ref={(el) => (cardRefs.current[3] = el)}
              >
                <Card.Body>
                  <h2 id="skills-master" className="mb-3">
                    Skills You Will Master
                  </h2>
                  <Row>
                    {courseData.skills.map((skill, index) => (
                      <Col md={6} key={index} className="mb-4">
                        <Card
                          className="h-100 shadow-sm zoom-card"
                          ref={(el) => (cardRefs.current[index + 4] = el)}
                        >
                          <Card.Body>
                            <h5>
                              {skill.icon} {skill.title}
                            </h5>
                            <p>{skill.description}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </section>

            {/* Program Details */}
            <section aria-labelledby="program-details">
              <Row>
                <Col md={6}>
                  <Card
                    className="zoom-card mb-5"
                    ref={(el) =>
                      (cardRefs.current[4 + courseData.skills.length] = el)
                    }
                  >
                    <Card.Body>
                      <h2 id="program-details" className="mb-3">
                        Program Details
                      </h2>
                      <ul>
                        <li>
                          <strong>Skill Level:</strong>{' '}
                          {courseData.programDetails.skillLevel}
                        </li>
                        <li>
                          <strong>Time to Complete:</strong>{' '}
                          {courseData.programDetails.timeToComplete}
                        </li>
                        <li>
                          <strong>Certification:</strong>{' '}
                          {courseData.programDetails.certification}
                        </li>
                        <li>
                          <strong>Prerequisites:</strong>{' '}
                          {courseData.programDetails.prerequisites}
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card
                    className="zoom-card mb-5"
                    ref={(el) =>
                      (cardRefs.current[5 + courseData.skills.length] = el)
                    }
                  >
                    <Card.Body>
                      <h2>Career Support</h2>
                      <ul>
                        {courseData.careerSupport.map((support, index) => (
                          <li key={index}>{support}</li>
                        ))}
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </section>

            {/* Call to Action */}
            <section aria-labelledby="cta-section">
              <Row>
                <Col md={6}>
                  <Card className="zoom-card mb-5">
                    <Card.Body>
                      <h2 id="cta-section" className="mb-3">
                        Why Choose Us?
                      </h2>
                      <ul>
                        {courseData.benefits.map((benefit, index) => (
                          <li key={index}>{benefit}</li>
                        ))}
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="text-center zoom-card mb-5">
                    <Card.Body>
                      <h4>Join the {courseData.title} Today</h4>
                      <p>{courseData.callToAction}</p>
                      <Button
                        variant="primary"
                        size="lg"
                        onClick={handleEnrollClick}
                      >
                        Enroll Now
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </section>

            {/* Download PDF */}
            <LoadPDF title={courseData.title} fileName={courseData.pdf} />
          </Container>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default CareerPathDetails;
