/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  Accordion,
  Modal,
  Button,
  Offcanvas,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown
import remarkGfm from 'remark-gfm';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import { filesParentPath } from './docsData.js';
import LoadQuizzes from '../feedComponents/Docs/Quizzes/LoadQuizzes.js';
import { CodeRenderer } from './CodeRenderer.js';
import { getSelectedDocSyllabus } from './SelectedDocsSyllabus.js';
import SEOCourseWise from './SEOs/SEOCourseWise.js';
import { webDevelopmentSkillsMapping } from './skillsList.js';

const TopicDetails = () => {
  const { topicNameParam } = useParams();

  const topicName = topicNameParam;

  const [concepts, setConcepts] = useState([]);
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [markdownContent, setMarkdownContent] = useState('');
  const [disablePreview, setDisablePreview] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [ModalComponent, setModalComponent] = useState(null); // Component to load in the modal
  const [showSidebar, setShowSidebar] = useState(false);

  const loadMarkdownFile = async (conceptName) => {
    try {
      console.log(`topicNameParam: ${topicNameParam}`);
      console.log(`conceptName: ${conceptName}`);
      let modifiedConceptName = conceptName.replace(/[^a-zA-Z0-9]/g, '_');
      console.log(`modifiedConceptName: ${modifiedConceptName}`);

      // Construct the dynamic module path
      const modulePath = `../hls-data/docs/${filesParentPath[topicName]}/${modifiedConceptName}.js`;
      console.log(`Loading content for concept File PATH: ${modulePath}`);

      // Dynamically import the module
      const { topicContent } = await import(
        /* webpackInclude: /\.js$/ */
        `../hls-data/docs/${filesParentPath[topicName]}/${modifiedConceptName}.js`
      );

      setMarkdownContent(topicContent);
    } catch (error) {
      console.error(`Error loading module: ${error.message}`);
    }
  };

  // const loadQuizData = async () => {
  //   try {
  //     let modifiedConceptName = conceptName.replace(/[^a-zA-Z0-9]/g, '_');
  //     const { questions } = await import(
  //       `../${topicName}/ConceptQuizzes/${modifiedConceptName}_Quiz.js`
  //     );
  //     setCurrentQuestions(questions);
  //   } catch (error) {
  //     console.error(`Error loading quiz for concept "${conceptName}":`, error);
  //     alert(`Quiz Loading for **${conceptName}**...`);
  //   }
  // };

  const handleConceptClick = async (concept) => {
    console.log(`Clicked on concept: ${JSON.stringify(concept, null, 4)}`);
    await loadMarkdownFile(concept.name);
    setSelectedConcept(concept);
  };

  useEffect(() => {
    console.log(`topicName: ${topicName}`);
    const conceptSyllabus = getSelectedDocSyllabus(
      webDevelopmentSkillsMapping[topicName]
    );
    setConcepts(conceptSyllabus);
    // Automatically select the first topic
    if (conceptSyllabus.length > 0) {
      const firstChapter = conceptSyllabus[0];
      if (firstChapter.sections.length > 0) {
        const firstSection = firstChapter.sections[0];
        if (firstSection.topics?.length > 0) {
          const firstTopic = firstSection.topics[0];
          setSelectedConcept(firstTopic);
          loadMarkdownFile(firstTopic.name);
        }
      }
    }
  }, [topicName]);

  const handleOpenMemorizingModal = () => {
    console.log(`selectedConcept: ${JSON.stringify(selectedConcept, null, 4)}`);

    // Dynamically load the component you want to display in the modal
    setModalComponent(() => {
      const Component = (props) => (
        <LoadQuizzes
          {...props}
          understanding={selectedConcept?.understanding || false}
          memorization={selectedConcept?.memorization || true}
          topicName={topicName}
          conceptName={selectedConcept.name}
        />
      );
      Component.displayName = 'DynamicLoopsQuiz';
      return Component;
    });

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalComponent(null); // Clear the modal component
  };

  const renderers = {
    code: ({ node, children }) => (
      <CodeRenderer node={node} disablePreview={disablePreview}>
        {children}
      </CodeRenderer>
    ),
  };
  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  if (!topicName) {
    return <h2 className="text-center mt-5">Loading...</h2>;
  }

  // Memoize renderChaptersAndSections to avoid unnecessary re-renders
  const renderChaptersAndSections = useCallback(() => {
    if (!concepts || !Array.isArray(concepts)) {
      console.warn('No valid concepts array provided.');
      return <div style={{ color: 'red' }}>No concepts available.</div>;
    }

    console.log('Rendering Chapters and Sections:', concepts);

    return concepts.map((chapter, chapterIndex) => {
      console.log(`Rendering Chapter ${chapterIndex + 1}:`, chapter);

      // Check if the chapter contains the selected concept
      const isSelectedChapter = chapter.sections?.some((section) =>
        section.topics?.some(
          (topic) => topic.topic_id === selectedConcept?.topic_id
        )
      );

      return (
        <Accordion
          key={chapterIndex}
          defaultActiveKey={isSelectedChapter ? chapterIndex.toString() : null}
        >
          <Accordion.Item eventKey={chapterIndex.toString()}>
            <Accordion.Header>{chapter.name}</Accordion.Header>
            <Accordion.Body>
              {Array.isArray(chapter.sections) &&
              chapter.sections.length > 0 ? (
                chapter.sections.map((section, sectionIndex) => {
                  console.log(
                    `Rendering Section ${sectionIndex + 1} of Chapter ${chapterIndex + 1}:`,
                    section
                  );

                  // Check if section.topics is defined and is an array
                  if (!section.topics || !Array.isArray(section.topics)) {
                    console.warn(
                      `Section ${sectionIndex + 1} of Chapter ${chapterIndex + 1} has no valid topics.`,
                      section
                    );
                    return (
                      <div
                        key={sectionIndex}
                        style={{ color: 'red', fontStyle: 'italic' }}
                      >
                        No topics available.
                      </div>
                    );
                  }

                  // Check if the section contains the selected concept
                  const isSelectedSection = section.topics?.some(
                    (topic) => topic.topic_id === selectedConcept?.topic_id
                  );

                  return (
                    <Accordion
                      key={sectionIndex}
                      defaultActiveKey={
                        isSelectedSection
                          ? `${chapterIndex}-${sectionIndex}`
                          : null
                      }
                    >
                      <Accordion.Item
                        eventKey={`${chapterIndex}-${sectionIndex}`}
                      >
                        <Accordion.Header>{section.name}</Accordion.Header>
                        <Accordion.Body>
                          <ListGroup>
                            {section.topics.map((topic, topicIndex) => {
                              console.log(
                                `Rendering Topic ${topicIndex + 1} of Section ${sectionIndex + 1}, Chapter ${chapterIndex + 1}:`,
                                topic
                              );

                              return (
                                <ListGroup.Item
                                  key={topic.topic_id}
                                  action
                                  onClick={() => handleConceptClick(topic)}
                                  active={
                                    selectedConcept?.topic_id === topic.topic_id
                                  }
                                  className="text-dark"
                                >
                                  {topic.topic_id ? `${topic.topic_id}: ` : ''}
                                  {topic.name}
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })
              ) : (
                <div style={{ color: 'red', fontStyle: 'italic' }}>
                  No sections available.
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    });
  }, [concepts, selectedConcept, handleConceptClick]);

  return (
    <>
      {/* Metadata for SEO */}
      <SEOCourseWise courseName={topicName} />

      {/* Main Layout */}
      <div
        className="d-flex flex-column"
        style={{
          height: '100vh', // Full screen height
          overflow: 'hidden', // Prevent scrolling
        }}
      >
        {/* Navbar */}
        <header>
          <Navbar />
        </header>

        {/* Main Content */}
        <main className="flex-grow-1" style={{ overflow: 'hidden' }}>
          <Container className="mt-4" style={{ height: '100%' }}>
            <div className="d-flex" style={{ height: '100%' }}>
              {/* Sidebar */}
              <Offcanvas
                show={showSidebar}
                onHide={handleCloseSidebar}
                responsive="lg"
                style={{
                  width: '300px',
                  backgroundColor: '#f8f9fa',
                  borderRight: '1px solid #dee2e6',
                  height: '100%',
                }}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Concepts</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body
                  style={{
                    overflowY: 'auto', // Enables scrolling for the sidebar content
                    maxHeight: '100%', // Ensures the content height is limited to the viewport
                  }}
                >
                  <nav aria-label="Concepts navigation">
                    {renderChaptersAndSections()}
                    <div className="mt-3">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        className="w-100"
                        aria-pressed={disablePreview}
                        onClick={() => setDisablePreview(!disablePreview)}
                      >
                        {disablePreview
                          ? 'Enable Output Preview'
                          : 'Disable Output Preview'}
                      </Button>
                    </div>
                  </nav>
                </Offcanvas.Body>
              </Offcanvas>

              {/* Toggle Button for Sidebar */}
              <Button
                variant="primary"
                onClick={handleShowSidebar}
                className="d-lg-none"
                style={{
                  position: 'fixed',
                  top: '100px',
                  left: '50%', // Center horizontally
                  transform: 'translateX(-50%)', // Adjust for the button's width
                  zIndex: 1000,
                }}
              >
                Show Concepts
              </Button>

              {/* Details Section */}
              <section
                style={{
                  flex: 1,
                  height: '100%',
                  overflowY: 'auto',
                  padding: '1rem',
                }}
              >
                {markdownContent ? (
                  <Card as="section" aria-labelledby="concept-details">
                    <Card.Body>
                      <Card.Title
                        id="concept-details"
                        className="text-primary d-flex justify-content-between align-items-center"
                      >
                        {selectedConcept?.name}
                        <Button
                          variant="info"
                          size="sm"
                          onClick={handleOpenMemorizingModal}
                        >
                          Quiz
                        </Button>
                      </Card.Title>
                      <ReactMarkdown
                        components={renderers}
                        remarkPlugins={[remarkGfm]}
                      >
                        {markdownContent || 'No description available'}
                      </ReactMarkdown>
                    </Card.Body>
                  </Card>
                ) : (
                  <div className="text-center mt-5">
                    <h2 className="text-secondary">
                      Select a concept to view its details
                    </h2>
                  </div>
                )}
              </section>
            </div>
          </Container>
        </main>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedConcept?.name} - Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ModalComponent ? <ModalComponent /> : <p>Loading...</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TopicDetails;
