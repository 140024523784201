import React, { useState, useEffect } from 'react';
import { loopsContent } from './sampleData.js';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

const interactiveMemorizerInfo = `
🤔 **Interactive Memorization** transforms passive learning into active engagement, boosting retention and understanding. It involves direct participation, like recalling information, solving problems, or answering questions, making learning more effective and enjoyable.

### 🌟 Why Choose Interactive Memorization?
1. **🧠 Active Engagement**: Strengthens neural connections by making your brain work 🏋️‍♂️ to retrieve and process information.
2. **🗂️ Better Retention**: Repeated recall 📚 reinforces memory pathways for long-term retention.
3. **🎯 Targeted Improvement**: Identifies weak areas 🩹, allowing you to focus where it matters most.
4. **🎮 Fun and Motivating**: Gamified elements 🎉 make learning enjoyable and sustain your interest.

### 🚀 How It Helps:
- **💪 Builds Confidence**: Success in recalling information 💡 boosts your self-belief.
- **🧬 Promotes Neuroplasticity**: Activates multiple brain areas 🌍 for adaptable, long-term learning.
- **⚡ Efficient Feedback**: Learn faster 🏃‍♂️ with instant corrections and relearning opportunities.

✨ Start your interactive memorization journey today and unlock your full learning potential! 🚀💡👩‍🎓👨‍🎓
`;

function isMobileDevice() {
  if (typeof navigator === 'undefined') return false;
  return /Mobi|Android/i.test(navigator.userAgent);
}

const InteractiveMemorizer = ({ content = null }) => {
  const [userInput, setUserInput] = useState(content || loopsContent);
  const [language, setLanguage] = useState('en-US');
  const [voice, setVoice] = useState(null);
  const [preloadedUtterances, setPreloadedUtterances] = useState({});
  const words = userInput.replace(/\n/g, ' ').trim().split(' ');
  const [predictedParagraph, setPredictedParagraph] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWordSet, setCurrentWordSet] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [isRecollecting, setIsRecollecting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSpeaking, setSpeaking] = useState(false);

  // NEW: Track auto-playing
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);

  const synth = window.speechSynthesis;
  const mobile = isMobileDevice();

  useEffect(() => {
    const availableVoices = synth.getVoices();
    if (availableVoices.length > 0) {
      const defaultVoice = availableVoices.find((v) => v.lang === language);
      setVoice(defaultVoice || availableVoices[0]);
    }
    synth.onvoiceschanged = () => {
      const updatedVoices = synth.getVoices();
      const defaultVoice = updatedVoices.find((v) => v.lang === language);
      setVoice(defaultVoice || updatedVoices[0]);
    };
  }, [language, synth]);

  useEffect(() => {
    if (isStarted || isRecollecting) {
      // Preload for the first (or current) index whenever we start or recollect
      preloadUtterances(currentWordIndex);
    }
  }, [isStarted, isRecollecting]);

  // NEW: Auto-play effect
  // Whenever `isAutoPlaying` is true, and we are not currently speaking,
  // automatically select the correct word until the content is completed.
  useEffect(() => {
    if (
      isAutoPlaying &&
      isStarted &&
      !isSpeaking &&
      currentWordIndex < words.length
    ) {
      // Automatically "click" the correct word
      handleWordClick(words[currentWordIndex]);
    }
  }, [isAutoPlaying, isSpeaking, currentWordIndex, isStarted, words]);

  const preloadUtterances = (startIndex) => {
    const newUtterances = {};
    // Load a few words ahead (here just 1 for demonstration)
    for (let i = startIndex; i < startIndex + 1 && i < words.length; i++) {
      const utterance = new SpeechSynthesisUtterance(words[i]);
      utterance.lang = language;
      utterance.voice = voice;
      newUtterances[i] = utterance;
    }
    setPreloadedUtterances((prev) => ({ ...prev, ...newUtterances }));
  };

  const speakText = (textIndex, callback) => {
    // Set speaking status to true
    setSpeaking(true);

    // Cancel any ongoing speech first
    if (synth.speaking) {
      synth.cancel();
    }

    const utterance = preloadedUtterances[textIndex];
    if (utterance) {
      utterance.onend = () => {
        setSpeaking(false);
        if (callback) callback();
      };
      utterance.onerror = (event) => {
        console.error('Speech synthesis error:', event.error);
        setSpeaking(false);
        if (callback) callback();
      };
      synth.speak(utterance);
    } else {
      console.error('No utterance found for index:', textIndex);
      setSpeaking(false);
      if (callback) callback();
    }
  };

  const generateWordSet = (correctIndex) => {
    const correctWord = words[correctIndex];
    const randomWords = words.filter(
      (w, index) => index !== correctIndex && w !== correctWord
    );
    const distractors = randomWords.sort(() => Math.random() - 0.5).slice(0, 2);
    return [correctWord, ...distractors].sort(() => Math.random() - 0.5);
  };

  const startGame = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsStarted(true);
    setIsRecollecting(false);
    setIsAutoPlaying(false); // Make sure auto-play is off if user chooses normal start
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    setCurrentWordSet(generateWordSet(0));
  };

  const resetGame = () => {
    if (synth.speaking) synth.cancel();
    setIsStarted(false);
    setIsRecollecting(false);
    setIsCompleted(false);
    setIsAutoPlaying(false);
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    setCurrentWordSet([]);
    setPreloadedUtterances({});
  };

  const startRecollection = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsRecollecting(true);
    setIsStarted(false);
    setIsAutoPlaying(false);
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    setCurrentWordSet(generateWordSet(0));
  };

  // NEW: Auto start game with auto-playing set to true
  const autoStartGame = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsStarted(true);
    setIsRecollecting(false);
    setIsAutoPlaying(true); // Turn on auto-play
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    setCurrentWordSet(generateWordSet(0));
  };

  // Handle word selection (manual or auto)
  const handleWordClick = (word) => {
    const correctWord = words[currentWordIndex];
    if (word === correctWord) {
      speakText(currentWordIndex, () => {
        setPredictedParagraph((prev) => [...prev, correctWord]);
        const nextIndex = currentWordIndex + 1;

        if (nextIndex < words.length) {
          setCurrentWordIndex(nextIndex);
          setCurrentWordSet(generateWordSet(nextIndex));
          preloadUtterances(nextIndex);
        } else {
          setCurrentWordSet([]);
          setIsCompleted(true);
          setIsStarted(false);
          setIsAutoPlaying(false); // Stop auto-playing if we reached the end
        }
      });
    }
  };

  // Format predicted paragraph to match original content line breaks
  const formatPredictedParagraph = () => {
    const originalLines = userInput.split('\n');
    let predictedIndex = 0;
    return originalLines.map((line, idx) => {
      const lineWords = line.trim().split(' ');
      const formattedLine = lineWords
        .map(() => predictedParagraph[predictedIndex++] || '')
        .join(' ');
      return <div key={idx}>{formattedLine}</div>;
    });
  };

  return (
    <Container
      fluid
      style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}
    >
      {/* Header Section */}
      <Row className="mb-4">
        <Col md={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Header as="h2" className="text-center">
              ✨ <b>Why</b> Interactive Memorizer...✨
            </Card.Header>
            {!isStarted && !isRecollecting && (
              <Card.Body
                style={{
                  maxHeight: '110px', // Limit card height
                  overflowY: 'auto', // Enable vertical scrolling
                  padding: '15px', // Adjust padding as needed
                }}
              >
                <ReactMarkdown>{interactiveMemorizerInfo}</ReactMarkdown>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>

      {/* Language Selector */}
      {!isStarted && !isRecollecting && (
        <Row className="mb-4">
          <Col md={{ span: 4, offset: 4 }}>
            <Card>
              <Card.Header as="h4">🌐 Select Language</Card.Header>
              <Card.Body>
                <Form.Group controlId="languageSelect">
                  <Form.Control
                    as="select"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="en-US">English</option>
                    <option value="te-IN">Telugu</option>
                    <option value="hi-IN">Hindi</option>
                  </Form.Control>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Content Input Section */}
      {!isStarted && !isRecollecting && (
        <Row className="mb-4">
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Header as="h4">📝 Enter Content:</Card.Header>
              <Card.Body>
                <Form.Group controlId="userInput">
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    disabled={isStarted || isRecollecting}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Game Controls */}
      <Row className="mb-4 text-center">
        <Col>
          {!isStarted && !isRecollecting && (
            <>
              <Button onClick={startGame} variant="primary" className="me-2">
                🚀 Start
              </Button>
              {/* NEW: Auto Start Button */}
              <Button
                onClick={autoStartGame}
                variant="success"
                className="me-2"
              >
                ⚡ Auto Start
              </Button>
              <Button
                onClick={startRecollection}
                variant="info"
                className="me-2"
              >
                🔄 Recollect
              </Button>
            </>
          )}
          {(isStarted || isRecollecting || isAutoPlaying) && (
            <Button onClick={resetGame} variant="danger" className="me-2">
              🔁 Reset
            </Button>
          )}
        </Col>
      </Row>

      {/* Active Game Section */}
      {(isStarted || isRecollecting) && (
        <>
          {/* Predicted Paragraph */}
          <Row className="mb-4">
            <Col md={{ span: 8, offset: 2 }}>
              <Card>
                <Card.Body
                  style={{
                    padding: '10px',
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    minHeight: '50px',
                  }}
                >
                  {formatPredictedParagraph()}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Word Buttons */}
          {!isCompleted && (
            <Row className="justify-content-center">
              <Col xs="auto">
                <Card>
                  <Card.Body
                    style={{
                      display: 'flex',
                      gap: '10px',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    {currentWordSet.map((word, index) => (
                      <Button
                        key={index}
                        variant="light"
                        style={{
                          padding: '10px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          color: isRecollecting
                            ? isSpeaking && word === words[currentWordIndex]
                              ? 'white'
                              : 'inherit'
                            : word === words[currentWordIndex]
                              ? 'white' // Green when not recollecting and the word is correct
                              : 'inherit', // Inherit for all other cases
                          backgroundColor: isRecollecting
                            ? isSpeaking && word === words[currentWordIndex]
                              ? 'green'
                              : 'inherit'
                            : word === words[currentWordIndex]
                              ? 'green' // Green when not recollecting and the word is correct
                              : 'inherit', // Inherit for all other cases
                        }}
                        disabled={isSpeaking || isAutoPlaying}
                        // For mobile browsers: onClick
                        onClick={
                          mobile ? () => handleWordClick(word) : undefined
                        }
                        // For desktop browsers: onMouseEnter
                        onMouseEnter={
                          !mobile ? () => handleWordClick(word) : undefined
                        }
                      >
                        {word}
                      </Button>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}

      {/* Completion Message */}
      {isCompleted && (
        <Row className="mt-4">
          <Col md={{ span: 8, offset: 2 }}>
            <Card className="text-center">
              <Card.Body>
                <h4 className="text-success">
                  🎉 Great job! You&apos;ve completed the{' '}
                  {isRecollecting ? (
                    <b>🔄 recollection</b>
                  ) : (
                    <b>🧠 memorization</b>
                  )}
                  !
                </h4>
                <h5 className="text-info">
                  ✍️ Try again to improve your
                  {isRecollecting ? (
                    <b> 🔄 recollection</b>
                  ) : (
                    <b> 🧠 memorization</b>
                  )}
                  !
                </h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default InteractiveMemorizer;
