/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import MonacoEditor from '@monaco-editor/react';
import prettier from 'prettier/standalone';
import * as parserBabel from 'prettier/plugins/babel';
import * as parserHtml from 'prettier/plugins/html';
import { Container, Row, Col, Button, Card, Accordion } from 'react-bootstrap';
import debounce from 'lodash/debounce.js';
import { ToastContainer, toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import 'react-toastify/dist/ReactToastify.css';
import './CodingActivityView.css'; // Import the component-specific CSS file
import {
  getProgrammingLessonActivityById,
  // getCodingSubmissionByActivityId,
  // createCodingSubmission,
  updateCodingSubmission,
  executeCodeOnline,
  createUserProgress,
  updateUserProgress,
  getCourseProgrammingLanguageByCourseId,
  getActivityJSONByCourseIdActivityID,
} from '../../services/api.js';
import {
  handleCourseNextActivity,
  handleCoursePreviousActivity,
} from '../../helpers/componentsHelper.js';

import { mathsEasyProblemStatements } from './MathsEasyProblemStatements.js';
import { matrixEasyProblemStatements } from './MatrixEasyProblemStatements.js';
import { greedyEasyProblemStatements } from './GreedyEasyProblemStatements.js';
import { dynamicProgrammingEasyProblemStatements } from './DynamicProgrammingEasyProblemStatements.js';
import { stringEasyProblemStatements } from './StringEasyProblemStatements.js';
import { arrayEasyProblemStatements } from './ArrayEasyProblemStatements.js';

const LoadProblemStatement = ({
  courseId,
  sectionId,
  activityType,
  activityId,
  codeExecutionLanguage,
  // programmingActivityId,
  onProgressUpdate,
}) => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState(null);
  const [userProgress, setUserProgress] = useState([]);
  const [code, setCode] = useState(
    '<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>Document</title>\n  <style>\n    /* Add your CSS here */\n  </style>\n</head>\n<body>\n  <h1>Happy Learning</h1>\n  <script>\n    // Add your JavaScript here\n  </script>\n</body>\n</html>\n'
  );
  const [programmingLanguage, setProgrammingLanguage] = useState(
    codeExecutionLanguage
  );
  const [result, setResult] = useState('');

  const [submission, setSubmission] = useState(null);
  const [currentInstructionIndex, setCurrentInstructionIndex] = useState(null); // To track the current instruction
  const [currentInstruction, setCurrentInstruction] = useState(null);
  const [Component, setComponent] = useState(null);

  const createActivityUserProgress = useCallback(
    async (activityId, courseId, instructionId, progressPercentage) => {
      try {
        const token = localStorage.getItem('token');
        const progressData = {
          courseId,
          activityId,
          instructionId,
          status: progressPercentage === 100 ? 'completed' : 'in_progress',
          progressPercentage,
        };

        await createUserProgress(token, progressData);
        console.log('Activity Start Progress updated successfully!');
        onProgressUpdate();
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data
          : error.message;
        console.error('Error updating progress:', errorMessage);
        alert('Failed to update progress. Please try again.');
      }
    },
    [onProgressUpdate]
  );

  const fetchActivityData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');

      // Fetch activity data
      const activityResponse = await getActivityJSONByCourseIdActivityID(
        token,
        courseId,
        sectionId,
        activityType,
        activityId
      );
      console.log(
        `activityResponse: ${JSON.stringify(activityResponse, null, 4)}`
      );

      if (
        codeExecutionLanguage.toLowerCase().includes('html') ||
        codeExecutionLanguage.toLowerCase().includes('css')
      ) {
        setCode(
          '<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>Document</title>\n  <style>\n    /* Add your CSS here */\n  </style>\n</head>\n<body>\n  <h1>Happy Learning</h1>\n  <script>\n    // Add your JavaScript here\n  </script>\n</body>\n</html>\n'
        );
      } else {
        setCode('console.log("Happy Learning");');
      }

      setActivity({
        ...activityResponse.data,
        // Instructions: updatedInstructions,
      });

      let { title } = activityResponse.data.activity;

      console.log(
        `activityResponse.data: ${JSON.stringify(activityResponse.data, null, 4)}`
      );

      console.log(`Loading Component: ${title}`);

      // Maths Easy Problem Statements Conditions
      mathsEasyProblemStatements(title, setComponent);
      matrixEasyProblemStatements(title, setComponent);
      greedyEasyProblemStatements(title, setComponent);
      dynamicProgrammingEasyProblemStatements(title, setComponent);

      // Arrays
      arrayEasyProblemStatements(title, setComponent);

      // String
      stringEasyProblemStatements(title, setComponent);

      // setUserProgress(userProgresses);
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      console.error('Error fetching coding activity:', errorMessage);
    }
    // }, [courseId, activityId, programmingActivityId, createActivityUserProgress]);
  }, [courseId, sectionId, activityType, activityId, codeExecutionLanguage]);

  useEffect(() => {
    fetchActivityData();
  }, [fetchActivityData]);

  const updateActivityUserProgress = async (
    userProgressId,
    progressPercentage
  ) => {
    try {
      const token = localStorage.getItem('token');
      const progressData = {
        status: progressPercentage === 100 ? 'completed' : 'in_progress',
        progressPercentage,
      };

      await updateUserProgress(token, userProgressId, progressData);
      console.log('Activity Completed Progress updated successfully!');
      fetchActivityData(); // Refetch activity data after progress update
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      console.error('Error updating progress:', errorMessage);
      alert('Failed to update progress. Please try again.');
    }
  };

  const executeCodeBasic = async () => {
    try {
      if (
        codeExecutionLanguage.toLowerCase().includes('html') ||
        codeExecutionLanguage.toLowerCase().includes('css')
      ) {
        const html = code;
        const completeCode = `
          ${html}
      `;

        const iframe = document.getElementById('outputIframe');
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(completeCode);
        iframeDoc.close();
      } else {
        const token = localStorage.getItem('token');

        // Execute the code online
        const response = await executeCodeOnline(token, {
          language: programmingLanguage.toLowerCase(),
          code,
          test_type: '',
          test_code: '',
        });

        if (!response?.data?.run) {
          throw new Error('No valid response from the code execution API.');
        }

        console.log(
          `response.data.run: ${JSON.stringify(response.data.run, null, 4)} `
        );

        const {
          stdout = '',
          stderr = '',
          code: exitCode = 0,
        } = response.data.run;

        console.log(`stdout: ${stdout} `);

        // Prepare HTML content to display results
        let resultHtml = `
        < div >
        <pre>${stdout}</pre>
              ${stderr ? `<pre style="color: red;">Error: ${stderr}</pre>` : ''}
              ${exitCode !== 0 ? `<pre style="color: red;">Exit code: ${exitCode}</pre>` : ''}
            
          </div >
  `;

        // Write the result into the iframe
        const iframe = document.getElementById('outputIframe');
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(resultHtml);
        iframeDoc.close();

        // Set result for stdout and stderr (if you still want to keep this for other usage)
        if (stdout) setResult(stdout);
        if (stderr)
          setResult((prevResult) => `${prevResult} \nError: ${stderr} `);
        if (exitCode !== 0)
          setResult(
            (prevResult) => `${prevResult} \nError: Exit code: ${exitCode} `
          );
      }
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || 'Unknown error';
      setResult(`Error: ${errorMessage} `);
    }
  };

  const executeCodeComplete = async () => {
    try {
      const token = localStorage.getItem('token');

      if (!currentInstruction) {
        throw new Error('Current instruction is not available');
      }

      console.log(
        `22 currentInstruction: ${JSON.stringify(currentInstruction, null, 4)} `
      );

      const instructionTestingType =
        currentInstruction?.Hints?.[0]?.ActivityTestCases?.[0]?.test_type || '';
      const instructionTestingCode =
        currentInstruction?.Hints?.[0]?.ActivityTestCases?.[0]?.test_code || '';

      console.log(`instructionTestingType: ${instructionTestingType} `);
      console.log(`code: ${code} `);
      console.log(`instructionTestingCode: ${instructionTestingCode} `);

      // Execute the code online
      const response = await executeCodeOnline(token, {
        language: programmingLanguage.toLowerCase(),
        code,
        test_type: instructionTestingType,
        test_code: instructionTestingCode,
      });

      if (!response?.data?.run) {
        throw new Error('No valid response from the code execution API.');
      }

      console.log(`response.data.run: ${JSON.stringify(response, null, 4)} `);

      const {
        stdout = '',
        stderr = '',
        code: exitCode = 0,
      } = response.data.run;
      const instructionResults = {};

      // Validate `userProgress` and `activity`
      if (!userProgress || !activity?.Instructions) {
        throw new Error(
          'User progress or activity instructions are not available'
        );
      }

      for (let i = 0; i < userProgress.length; i++) {
        const instruction = activity.Instructions[i];

        console.log(
          `11 Compare instruction: ${JSON.stringify(instruction, null, 4)} `
        );
        console.log(
          `22 Compare currentInstruction: ${JSON.stringify(currentInstruction, null, 4)} `
        );

        // Compare instructions
        const testCaseMatched = instruction === currentInstruction;
        if (testCaseMatched) {
          instructionResults[instruction?.UserProgresses?.[0]?.progress_id] =
            testCaseMatched;
        }
      }

      // Set result for stdout and stderr
      if (stdout) setResult(stdout);
      if (stderr)
        setResult((prevResult) => `${prevResult} \nError: ${stderr} `);
      if (exitCode !== 0)
        setResult(
          (prevResult) => `${prevResult} \nError: Exit code: ${exitCode} `
        );

      let testStatus = false;
      // Show the first failed test case hint, if applicable
      if (stdout.includes('Test failed with error: ')) {
        const errorMessage = stdout.replace('Test failed with error: ', '');
        toast.error(`Hint: ${errorMessage} `);
      } else {
        // Update user progress for each instruction
        for (const [userProgressId, isPassed] of Object.entries(
          instructionResults
        )) {
          if (userProgressId) {
            await updateActivityUserProgress(
              userProgressId,
              isPassed ? 100 : 0
            );
          }
        }
        testStatus = true;
      }

      // Update the coding submission
      await updateCodingSubmission(token, submission.submission_id, {
        code,
        result: stdout || stderr,
        status: testStatus ? 'success' : 'error',
        error_message: stderr || null,
      });

      // Trigger progress update callback
      onProgressUpdate();
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || 'Unknown error';
      setResult(`Error: ${errorMessage} `);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await updateCodingSubmission(token, submission.submission_id, {
        code,
        result,
      });
      alert('Coding activity submitted successfully!');
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      console.error('Error submitting coding activity:', errorMessage);
    }
  };

  // Function to format code using Prettier
  const formatCodeWithPrettier = async () => {
    try {
      const language = programmingLanguage.toLowerCase();

      // Determine the parser based on the programming language
      const parser =
        language === 'html' || language === 'html,css' ? 'html' : 'babel';

      // Determine the plugins to use based on the programming language
      const plugins =
        language === 'html' || language === 'html,css'
          ? [parserHtml]
          : [parserBabel];

      console.log('Type of Code:', typeof code);
      console.log(`Language: ${programmingLanguage.toLowerCase()} `);

      const formattedCode = await prettier.format(code, {
        parser,
        plugins,
        tabWidth: 2,
        semi: true,
        singleQuote: true,
        trailingComma: 'es5',
      });

      console.log('Formatted Code:', formattedCode);
      console.log('Type of Formatted Code:', typeof formattedCode);

      setCode(formattedCode);
    } catch (error) {
      console.error('Error formatting code with Prettier:', error);
    }
  };

  const handleCodeChange = debounce((value) => setCode(value || ''), 300);

  if (!activity) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="vh-100 d-flex flex-column coding-activity-view">
      {Component ? <Component /> : <p>Loading component...</p>}
    </Container>
  );
};

export default LoadProblemStatement;
