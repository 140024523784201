import React, { useState, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ProgressBar,
} from 'react-bootstrap';
import { motion } from 'framer-motion';

const LoopsForKids = () => {
  const animationConfigs = {
    fadeInFromBottom: (index) => ({
      initial: { opacity: 0, y: 20 },
      animate: { opacity: 1, y: 0 },
      transition: { delay: index * 0.2, duration: 0.5 },
    }),
    fadeInFromLeft: (index) => ({
      initial: { opacity: 0, x: -20 },
      animate: { opacity: 1, x: 0 },
      transition: { delay: index * 0.2, duration: 0.5 },
    }),
    scaleIn: (index) => ({
      initial: { opacity: 0, scale: 0 },
      animate: { opacity: 1, scale: 1 },
      transition: { delay: index * 0.15, duration: 0.5 },
    }),
    rotateIn: (index) => ({
      initial: { opacity: 0, rotateX: 90 },
      animate: { opacity: 1, rotateX: 0 },
      transition: { delay: index * 0.2, duration: 0.5, ease: 'easeInOut' },
    }),
    fadeInFromTop: (index) => ({
      initial: { opacity: 0, y: -20 },
      animate: { opacity: 1, y: 0 },
      transition: { delay: index * 0.15, duration: 0.5 },
    }),
    textWriting: (text) => ({
      initial: { width: '0ch' },
      animate: {
        width: `${text.length + 1}ch`,
        borderRight: '0px solid currentColor', // Cursor hidden after animation,
      },
      transition: { duration: 2, ease: 'easeInOut' },
      style: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontFamily: 'monospace',
      },
    }),
  };

  const scenes = [
    {
      title: 'Introduction to Loops 🔄',
      description:
        'A loop means repeating actions until a task is complete. Imagine clapping 5 times! Loops help in doing repetitive tasks automatically, saving time and effort.',
      content: [
        '👏 Clap once',
        '👏 Clap again',
        '👏 Keep clapping',
        '👏 Another clap',
        '👏 One last clap',
      ],
      animationType: 'fadeInFromBottom',
      styles: {
        backgroundColor: '#e3f2fd',
        headerBackground: 'linear-gradient(to right, #42a5f5, #1e88e5)',
        headerColor: '#ffffff',
      },
    },
    {
      title: 'Bake and Repeat 👩‍🍳🍪',
      description:
        'Baking cookies involves repeating steps like mixing dough for multiple cookies. Similarly, e-commerce systems process orders or package items using loops.',
      content: [
        '🍪 Mix dough for Cookie 1',
        '🍪 Mix dough for Cookie 2',
        '🍪 Mix dough for Cookie 3',
      ],
      animationType: 'fadeInFromLeft',
      styles: {
        backgroundColor: '#fff3e0',
        headerBackground: 'linear-gradient(to right, #ffb74d, #f57c00)',
        headerColor: '#ffffff',
      },
    },
    {
      title: 'Nurturing Nature 💧🌻',
      description:
        'Watering flowers involves repeating the same task for each plant. Similarly, IoT systems automate repetitive tasks like watering plants or managing smart gardens.',
      content: [
        '🌻 Water Flower 1',
        '🌻 Water Flower 2',
        '🌻 Water Flower 3',
        '🌻 Water Flower 4',
      ],
      animationType: 'scaleIn',
      styles: {
        backgroundColor: '#e8f5e9',
        headerBackground: 'linear-gradient(to right, #66bb6a, #43a047)',
        headerColor: '#ffffff',
      },
    },
    {
      title: 'Loops in Music 🎵',
      description:
        'Loops appear in music too! When you repeat the same part of a song, you’re using loops. Streaming platforms use loops to autoplay songs or videos endlessly.',
      content: ['🎶 La, La, La (repeat 3 times)'],
      animationType: 'textWriting',
      styles: {
        backgroundColor: '#ede7f6',
        headerBackground: 'linear-gradient(to right, #7e57c2, #5e35b1)',
        headerColor: '#ffffff',
      },
    },
    {
      title: 'Social Media Reload 🔄',
      description:
        'Social media uses loops to refresh feeds, fetch posts, load comments, and display notifications, ensuring your experience stays up to date in real-time.',
      content: [
        '📄 Fetch new posts from the server',
        '💬 Load user comments dynamically',
        '🔔 Display notifications (likes, shares, messages)',
        '♻️ Refresh the feed with new content',
      ],
      animationType: 'fadeInFromBottom',
      styles: {
        backgroundColor: '#e3f2fd',
        headerBackground: 'linear-gradient(to right, #1e88e5, #1565c0)',
        headerColor: '#ffffff',
      },
    },
    {
      title: 'Review Analysis 🛒',
      description:
        'E-commerce platforms process customer reviews to highlight the best ones, find trends, and keep recommendations up to date for every shopper.',
      content: [
        '⭐ Analyze reviews for ratings',
        '💡 Highlight the most helpful ones',
        '📈 Find trending products',
        '📚 Update wishlists and recommendations',
      ],
      animationType: 'fadeInFromLeft',
      styles: {
        backgroundColor: '#fff3e0',
        headerBackground: 'linear-gradient(to right, #fb8c00, #ef6c00)',
        headerColor: '#ffffff',
      },
    },
    {
      title: 'Customer Insights 📊',
      description:
        'Businesses analyze customer preferences, habits, and purchase history using loops to create personalized recommendations and targeted marketing.',
      content: [
        '📂 Track user browsing and purchase history',
        '💖 Create personalized recommendations',
        '📧 Send automated marketing emails',
        '📊 Measure ad performance',
      ],
      animationType: 'fadeInFromTop',
      styles: {
        backgroundColor: '#f3e5f5', // Light lavender background
        headerBackground: 'linear-gradient(to right, #ab47bc, #8e24aa)', // Purple gradient
        headerColor: '#ffffff', // White text for header
      },
    },
    {
      title: 'Smart Helpers 🤖',
      description:
        'Virtual assistants like Alexa or Siri use loops to continuously listen, analyze commands, and perform tasks seamlessly for you.',
      content: [
        '🎙️ Listen for commands in real time',
        '🧠 Analyze voice inputs',
        '✅ Respond or perform tasks',
        '📚 Train AI with datasets',
      ],
      animationType: 'rotateIn',
      styles: {
        backgroundColor: '#e8f5e9', // Light green background
        headerBackground: 'linear-gradient(to right, #43a047, #2e7d32)', // Green gradient
        headerColor: '#ffffff', // White text for header
      },
    },
    {
      title: 'You Did It! 🎉',
      description:
        'Congratulations on learning how loops work! You discovered their role in automating tasks, analyzing data, and even managing everyday tech tools.',
      content: [
        '⚙️ Automate repetitive tasks',
        '📊 Process data in industries',
        '🎯 Practice using loops in your projects',
      ],
      animationType: 'scaleIn',
      styles: {
        backgroundColor: '#fbe9e7',
        headerBackground: 'linear-gradient(to right, #e53935, #d32f2f)',
        headerColor: '#ffffff',
      },
    },
  ];

  const [currentScene, setCurrentScene] = useState(0);
  const [headerAnimationComplete, setHeaderAnimationComplete] = useState(false);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speakingWordIndex, setSpeakingWordIndex] = useState(0);

  const transitions = useTransition(currentScene, {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' },
    config: { tension: 150, friction: 20 },
  });

  const removeEmojis = (text) => {
    // Regex to match emojis with the 'u' flag
    return text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|\u0023\u20E3|\u00A9|\u00AE|[\u2000-\u3300])/gu,
      ''
    );
  };

  useEffect(() => {
    setHeaderAnimationComplete(false);

    // Remove emojis from the title and description
    const sanitizedTitle = removeEmojis(scenes[currentScene].title);
    const sanitizedDescription = removeEmojis(scenes[currentScene].description);
    const titleWords = sanitizedTitle.split(' '); // Split title into words
    const descriptionWords = sanitizedDescription.split(' ');

    const titleUtterance = new SpeechSynthesisUtterance(sanitizedTitle);
    const descriptionUtterance = new SpeechSynthesisUtterance(
      sanitizedDescription
    );

    let wordIndex = 0;

    // State to track if speech synthesis is active
    setIsSpeaking(true); // Mark as speaking initially

    // Show the first word of the title as the initial subtitle
    setCurrentSubtitle(titleWords[wordIndex]);
    setSpeakingWordIndex(wordIndex);

    // Event listener to update subtitles for the title
    titleUtterance.onboundary = () => {
      if (wordIndex < titleWords.length) {
        setCurrentSubtitle(titleWords[wordIndex]); // Update subtitle to the current word
        setSpeakingWordIndex(wordIndex); // Update the speaking word index
        wordIndex += 1;
      }
    };

    // Handle when title finishes speaking
    titleUtterance.onend = () => {
      wordIndex = 0; // Reset index for description
      setCurrentSubtitle(''); // Clear the subtitle before description starts

      // Event listener to update subtitles for the description
      descriptionUtterance.onboundary = () => {
        if (wordIndex < descriptionWords.length) {
          setCurrentSubtitle(descriptionWords[wordIndex]); // Update subtitle to the current word
          setSpeakingWordIndex(wordIndex); // Update the speaking word index
          wordIndex += 1;
        }
      };

      // Handle when description finishes speaking
      descriptionUtterance.onend = () => {
        setIsSpeaking(false); // Mark as not speaking when description ends
      };

      // Speak the description
      window.speechSynthesis.speak(descriptionUtterance);
    };

    // Start speaking the title
    window.speechSynthesis.speak(titleUtterance);

    // Cleanup on component unmount or scene change
    return () => {
      window.speechSynthesis.cancel();
      setCurrentSubtitle('');
      setSpeakingWordIndex(0); // Reset speakingWordIndex
      setIsSpeaking(false); // Reset isSpeaking
    };
  }, [currentScene]);

  const nextScene = () => {
    if (currentScene < scenes.length - 1) setCurrentScene(currentScene + 1);
  };

  const previousScene = () => {
    if (currentScene > 0) setCurrentScene(currentScene - 1);
  };

  const progress = ((currentScene + 1) / scenes.length) * 100;

  return (
    <Container
      fluid
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: '#f0f8ff',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      {transitions((style, index) => (
        <animated.div style={{ ...style, position: 'absolute', width: '100%' }}>
          <Row className="h-100 d-flex align-items-center justify-content-center">
            <Col xs={10} md={8} lg={6}>
              <Card
                className="shadow-lg"
                style={{
                  height: '400px',
                  borderRadius: '15px',
                  overflow: 'hidden',
                  backgroundColor: scenes[index].styles.backgroundColor,
                }}
              >
                <Card.Header
                  style={{
                    background: scenes[index].styles.headerBackground,
                    color: scenes[index].styles.headerColor,
                    fontSize: '2.0rem',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  <motion.span
                    {...animationConfigs.textWriting(`${scenes[index].title}`)}
                    onAnimationComplete={() => setHeaderAnimationComplete(true)} // Set state when header animation finishes
                  >
                    {scenes[index].title}
                  </motion.span>
                </Card.Header>
                <Card.Body
                  className="d-flex flex-column"
                  style={{
                    padding: '20px',
                  }}
                >
                  <Card.Text
                    style={{
                      display: 'flex', // Use flexbox
                      justifyContent: 'center', // Center horizontally
                      alignItems: 'center', // Center vertically
                      textAlign: 'left', // Align text to the center
                      overflowY: 'auto', // Allow vertical scrolling if content overflows
                      flexGrow: 1, // Make it grow to fill available space
                      marginTop: '10px', // Add a slight top margin
                      padding: '10px', // Add padding for better readability
                    }}
                  >
                    {headerAnimationComplete && // Wait until header animation completes
                      (scenes[index].animationType === 'textWriting' ? (
                        <motion.span
                          {...animationConfigs.textWriting(
                            scenes[index].content[0]
                          )}
                        >
                          {scenes[index].content[0]}
                        </motion.span>
                      ) : (
                        <motion.ul
                          initial="hidden"
                          animate="visible"
                          variants={{
                            hidden: { opacity: 0 },
                            visible: {
                              opacity: 1,
                              transition: {
                                staggerChildren: 0.2, // Delay between each child animation
                              },
                            },
                          }}
                        >
                          {scenes[index].content.map((item, itemIndex) => (
                            <motion.li
                              key={itemIndex}
                              {...animationConfigs[scenes[index].animationType](
                                itemIndex
                              )}
                            >
                              {/* Animate each line one after the other */}
                              <motion.span
                                initial={{ width: '0ch' }}
                                animate={{
                                  width: `${item.length + 1}ch`,
                                  borderRight: 'opx solid currentColor', // Cursor effect
                                }}
                                transition={{
                                  delay: itemIndex * 1, // Delay each line by index
                                  duration: 1.5, // Animation duration
                                  ease: 'easeInOut',
                                }}
                                style={{
                                  display: 'inline-block',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  fontFamily: 'monospace',
                                }}
                              >
                                {item}
                              </motion.span>
                            </motion.li>
                          ))}
                        </motion.ul>
                      ))}
                  </Card.Text>
                </Card.Body>
                {isSpeaking ? (
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '10px',
                      fontSize: '1.2rem',
                      color: '#ffffff', // White font color
                      minHeight: '50px', // Consistent height for the subtitle area
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '80%', // Fixed width for subtitle container
                      margin: '0 auto', // Center the container
                      borderRadius: '10px',
                    }}
                  >
                    {currentSubtitle && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        style={{
                          display: 'flex',
                          gap: '10px', // Spacing between words
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexWrap: 'wrap', // Handle longer subtitles gracefully
                        }}
                      >
                        {currentSubtitle.split(' ').map((word, index) => (
                          <span
                            key={index}
                            style={{
                              padding: '5px 10px',
                              borderRadius: '5px',
                              backgroundColor:
                                index === speakingWordIndex ? 'blue' : 'blue', // Blue for the speaking word
                              color:
                                index === speakingWordIndex
                                  ? '#ffffff'
                                  : '#ffffff', // Ensure contrast
                              fontWeight:
                                index === speakingWordIndex ? 'bold' : 'normal',
                              fontFamily: 'monospace',
                              transition: 'background-color 0.2s ease-in-out',
                            }}
                          >
                            {word}
                          </span>
                        ))}
                      </motion.div>
                    )}
                  </div>
                ) : null}
              </Card>

              <div className="mt-4">
                <ProgressBar
                  now={progress}
                  label={`${currentScene + 1}/${scenes.length}`}
                  className="mb-4"
                />
                {currentScene > 0 && (
                  <Button variant="secondary" onClick={previousScene}>
                    Back
                  </Button>
                )}
                {currentScene < scenes.length - 1 && (
                  <Button
                    variant="primary"
                    onClick={nextScene}
                    className="ms-2"
                  >
                    Next
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </animated.div>
      ))}
    </Container>
  );
};

export default LoopsForKids;
