import ProblemStatementFileDataLoader from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/String/ProblemStatementFileDataLoader.js';

const loadingProblemStatementData = (fileName) => {
  const ProblemStatementLoader = (props) => (
    <ProblemStatementFileDataLoader fileName={fileName} {...props} />
  );

  // Set display name explicitly
  ProblemStatementLoader.displayName = 'ProblemStatementLoader';

  return ProblemStatementLoader;
};

export const stringEasyProblemStatements = (title, setComponent) => {
  console.log(`REACHED: stringEasyProblemStatements`);
  if (title === 'Shortest Word Distance') {
    const fileName = '243ShortestWordDistanceStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Valid Parentheses') {
    const fileName = '20ValidParenthesesStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Valid Anagram') {
    const fileName = '242ValidAnagramStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Length of Last Word') {
    const fileName = '58LengthofLastWordStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Roman to Integer') {
    const fileName = '13RomantoIntegerStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Excel Sheet Column Number') {
    const fileName = '171ExcelSheetColumnNumberStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Reverse String') {
    const fileName = '344ReverseStringStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find the Difference') {
    const fileName = '389FindtheDifferenceStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Ransom Note') {
    const fileName = '383RansomNoteStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Strobogrammatic Number') {
    const fileName = '246StrobogrammaticNumberStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Longest Common Prefix') {
    const fileName = '14LongestCommonPrefixStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Is Subsequence') {
    const fileName = '392IsSubsequenceStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Flip Game') {
    const fileName = '293FlipGameStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Excel Sheet Column Title') {
    const fileName = '168ExcelSheetColumnTitleStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Isomorphic Strings') {
    const fileName = '205IsomorphicStringsStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Valid Word Abbreviation') {
    const fileName = '408ValidWordAbbreviationStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Reverse Vowels of a String') {
    const fileName = '345ReverseVowelsofaStringStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Palindrome Permutation') {
    const fileName = '266PalindromePermutationStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Longest Palindrome') {
    const fileName = '409LongestPalindromeStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'First Unique Character in a String') {
    const fileName = '387FirstUniqueCharacterinaStringStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Add Binary') {
    const fileName = '67AddBinaryStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find the Index of the First Occurrence in a String') {
    const fileName =
      '28FindtheIndexoftheFirstOccurrenceinaStringStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Binary Tree Paths') {
    const fileName = '257BinaryTreePathsStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Valid Palindrome') {
    const fileName = '125ValidPalindromeStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Word Pattern') {
    const fileName = '290WordPatternStringEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  }
};
