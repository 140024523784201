import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar.js';
import HeroSection from '../../components/HeroSection.js';
import FeaturedCourses from '../../components/FeaturedCourses.js';
import Benefits from '../../components/Benefits.js';
import Testimonials from '../../components/Testimonials.js';
import Pricing from '../../components/Pricing.js';
import Footer from '../../components/Footer.js';
import CareerPaths from '../../components/CareerPaths.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const JobHomepage = () => {
  return (
    <>
      {/* Metadata for SEO */}
      <Helmet>
        <title>Happy Learning Solutions - Empowering Your Future</title>
        <meta
          name="description"
          content="Discover curated courses, career paths, and personalized learning experiences with Happy Learning Solutions. Empower your future today!"
        />
        <meta
          name="keywords"
          content="learning, education, online courses, career development, personalized learning, Happy Learning Solutions"
        />
        <meta name="author" content="Happy Learning Solutions" />
      </Helmet>

      {/* Page Content */}
      <div className="d-flex flex-column min-vh-100">
        {/* Navigation Bar */}
        <header>
          <Navbar />
        </header>

        {/* Main Content */}
        <main>
          <section aria-labelledby="hero-section">
            <HeroSection />
          </section>

          <section aria-labelledby="career-paths-section">
            <h2 id="career-paths-section" className="visually-hidden">
              Career Paths
            </h2>
            <CareerPaths />
          </section>

          <section aria-labelledby="featured-courses-section">
            <h2 id="featured-courses-section" className="visually-hidden">
              Featured Courses
            </h2>
            <FeaturedCourses />
          </section>

          <section aria-labelledby="benefits-section">
            <h2 id="benefits-section" className="visually-hidden">
              Benefits of Learning with Us
            </h2>
            <Benefits />
          </section>

          <section aria-labelledby="testimonials-section">
            <h2 id="testimonials-section" className="visually-hidden">
              Testimonials from Our Learners
            </h2>
            <Testimonials />
          </section>

          <section aria-labelledby="pricing-section">
            <h2 id="pricing-section" className="visually-hidden">
              Pricing and Plans
            </h2>
            <Pricing hideHeaderFooter={true} />
          </section>
        </main>

        {/* Footer */}
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default JobHomepage;
