import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import { topics } from './docsData.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { webDevelopmentSkillsMappingUpper } from './skillsList.js';

const TopicsList = () => {
  const navigate = useNavigate();

  const handleCardClick = (languageName) => {
    navigate(`/${languageName}`);
  };

  return (
    <>
      {/* Metadata for SEO */}
      <Helmet>
        <title>Documentation Topics - Explore Guides and Resources</title>
        <meta
          name="description"
          content="Explore a wide range of documentation topics. Find detailed guides, descriptions, and common uses for various technologies and programming languages."
        />
        <meta
          name="keywords"
          content="documentation, guides, programming, learning, resources, tutorials, technology"
        />
        <meta name="author" content="Happy Learning Solutions" />
      </Helmet>

      {/* Page Content */}
      <div className="d-flex flex-column min-vh-100">
        {/* Navigation */}
        <header>
          <Navbar />
        </header>

        {/* Main Content */}
        <main>
          <Container className="mt-5">
            <h1 className="text-center mb-4" aria-labelledby="docs-title">
              Documentation Topics
            </h1>

            <section aria-labelledby="topics-section">
              <h2 id="topics-section" className="visually-hidden">
                Explore Documentation Topics
              </h2>
              <Row>
                {topics.map((language, index) => (
                  <Col key={index} md={6} className="mb-4">
                    <article>
                      <Card
                        className="shadow-sm cursor-pointer"
                        onClick={() =>
                          handleCardClick(
                            webDevelopmentSkillsMappingUpper[language.name]
                          )
                        }
                        aria-labelledby={`card-title-${index}`}
                      >
                        <Card.Body>
                          <h3
                            id={`card-title-${index}`}
                            className="text-primary"
                          >
                            {language.name}
                          </h3>
                          <p>{language.description}</p>
                          <h5>Common Uses:</h5>
                          <ul>
                            {language.uses.map((use, useIndex) => (
                              <li key={useIndex}>{use}</li>
                            ))}
                          </ul>
                        </Card.Body>
                      </Card>
                    </article>
                  </Col>
                ))}
              </Row>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
};

export default TopicsList;
