import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import { bTechTopics } from './docsData.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const BTechHome = () => {
  const navigate = useNavigate();

  const handleCardClick = (languageName) => {
    console.log(`languageName: ${languageName}`);
    navigate(`/resources/docs/${languageName}`);
  };

  return (
    <>
      {/* Metadata for SEO */}
      <Helmet>
        <title>BTech Documentation - Comprehensive Guides</title>
        <meta
          name="description"
          content="Explore comprehensive documentation for BTech topics. Find guides, common uses, and detailed explanations for programming languages and engineering subjects."
        />
        <meta
          name="keywords"
          content="BTech, documentation, programming languages, engineering, guides, tutorials, learning resources"
        />
        <meta name="author" content="Happy Learning Solutions" />
      </Helmet>

      {/* Page Content */}
      <div className="d-flex flex-column min-vh-100">
        {/* Navigation */}
        <header>
          <Navbar />
        </header>

        {/* Main Section */}
        <main>
          <Container className="mt-5">
            <h1 className="text-center mb-4" aria-labelledby="docs-title">
              BTech Documentation
            </h1>

            <section aria-labelledby="docs-section">
              <Row>
                {bTechTopics.map((language, index) => (
                  <Col key={index} md={6} className="mb-4">
                    <article>
                      <Card
                        className="shadow-sm cursor-pointer"
                        onClick={() => handleCardClick(language.name)}
                        aria-labelledby={`card-title-${index}`}
                      >
                        <Card.Body>
                          <h3
                            id={`card-title-${index}`}
                            className="text-primary"
                          >
                            {language.name}
                          </h3>
                          <p>{language.description}</p>
                          <h5>Common Uses:</h5>
                          <ul>
                            {language.uses.map((use, useIndex) => (
                              <li key={useIndex}>{use}</li>
                            ))}
                          </ul>
                        </Card.Body>
                      </Card>
                    </article>
                  </Col>
                ))}
              </Row>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
};

export default BTechHome;
