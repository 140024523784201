import React, { useEffect, useState } from 'react';
import LoadProblemStatementData from './LoadProblemStatementData.js';

/**
 * This component is responsible for dynamically importing the content file
 * and displaying a loading message until it's available. Once loaded,
 * it renders the PalindromeNumberMathsEasyDemo with the loaded module data.
 *
 * @param {Object} props
 * @param {string} props.fileName - The base file name (e.g., "9PalindromeNumberMathsEasyContent")
 */
const ProblemStatementFileDataLoader = ({ fileName }) => {
  const [contentModule, setContentModule] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    async function loadContent() {
      try {
        const module = await import(`./PSContent/${fileName}`);
        if (isSubscribed) {
          setContentModule(module);
        }
      } catch (error) {
        console.error(`Failed to load content module: ${fileName}`, error);
      }
    }

    loadContent();

    return () => {
      isSubscribed = false;
    };
  }, [fileName]);

  if (!contentModule) {
    // Return a loading UI, but still do NOT call PalindromeNumberMathsEasyDemo yet
    return <div>Loading content for {fileName}...</div>;
  }

  return <LoadProblemStatementData contentModule={contentModule} />;
};

export default ProblemStatementFileDataLoader;
