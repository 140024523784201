export const topics = [
  {
    name: 'JavaScript',
    description:
      'The language of the web, essential for frontend and backend development.',
    uses: ['Web Development', 'Mobile App Development', 'Backend Development'],
  },
  {
    name: 'HTML',
    description:
      'An introductory course to HTML, focusing on the structure and creation of web pages. Learn to use HTML elements and attributes to create accessible and semantic content.',
    uses: [
      'Creating structured web pages',
      'Understanding web development fundamentals',
      'Foundation for advanced front-end technologies',
    ],
  },
  {
    name: 'CSS',
    description:
      'A comprehensive course on Cascading Style Sheets (CSS), focusing on styling web pages, creating layouts, and using modern design techniques. Explore responsive design, animations, and CSS frameworks.',
    uses: [
      'Designing visually appealing web pages',
      'Implementing responsive layouts',
      'Foundation for advanced web design techniques',
    ],
  },
  {
    name: 'JavaScript (ES6+)',
    description:
      'A course focusing on modern JavaScript (ES6+), covering features like arrow functions, modules, and destructuring. Learn to write clean and efficient JavaScript code using modern practices.',
    uses: [
      'Writing clean and modern JavaScript',
      'Leveraging advanced language features',
      'Improving development efficiency and maintainability',
    ],
  },
  {
    name: 'React.js',
    description:
      'A course on React.js, covering its component-based architecture, state management, and hooks. Learn to build dynamic and interactive user interfaces efficiently.',
    uses: [
      'Building interactive web applications',
      'Component-based UI development',
      'Efficient state management and performance optimization',
    ],
  },

  {
    name: 'Responsive Design (React.js)',
    description:
      'A course on creating responsive web designs using React.js. Learn techniques to ensure your applications adapt seamlessly to different screen sizes and devices.',
    uses: [
      'Building mobile-first applications',
      'Creating responsive and adaptive designs',
      'Enhancing user experience across devices',
    ],
  },
  {
    name: 'Node.js',
    description:
      'An introductory course to Node.js, covering its architecture, event-driven model, and use in building scalable network applications. Learn to create server-side applications and interact with databases.',
    uses: [
      'Building scalable backend systems',
      'Server-side programming with JavaScript',
      'Integration with databases and APIs',
    ],
  },
  {
    name: 'Express.js',
    description:
      'An introductory course to Express.js, a web application framework for Node.js. Learn to build RESTful APIs, manage middleware, and create dynamic web applications.',
    uses: [
      'Developing RESTful APIs',
      'Building server-side web applications',
      'Middleware management and routing',
    ],
  },
  {
    name: 'MongoDB',
    description:
      'An introductory course on MongoDB, a NoSQL database. Learn to design, query, and optimize schema-less databases for modern applications.',
    uses: [
      'Storing and managing unstructured data',
      'Building scalable applications',
      'Leveraging flexible data models',
    ],
  },
  {
    name: 'PostgreSQL',
    description:
      'A course focused on PostgreSQL, a powerful open-source relational database. Learn database design, querying, and optimization techniques to manage and retrieve data efficiently.',
    uses: [
      'Designing and managing relational databases',
      'Executing complex queries and data analysis',
      'Optimizing database performance',
    ],
  },

  {
    name: 'Asynchronous Programming In JavaScript',
    description:
      'A course designed to teach the principles of asynchronous programming in JavaScript, focusing on callbacks, promises, and async/await. Learn to handle asynchronous operations effectively for responsive and scalable applications.',
    uses: [
      'Efficient handling of asynchronous operations',
      'Improved application responsiveness',
      'Foundation for backend and frontend JavaScript development',
    ],
  },
  {
    name: 'Git',
    description:
      'An essential course on Git version control, covering repository management, branching, merging, and collaboration workflows. Master version control for efficient team development.',
    uses: [
      'Version control and history tracking',
      'Collaboration in software development teams',
      'Managing codebase changes and rollbacks',
    ],
  },
  {
    name: 'NPM/Yarn',
    description:
      'Learn to manage JavaScript dependencies using NPM and Yarn. This course covers package installation, version management, and creating reusable modules for efficient project management.',
    uses: [
      'Efficient dependency management',
      'Version control for libraries and tools',
      'Streamlining project setup and development',
    ],
  },
  {
    name: 'Webpack/Babel',
    description:
      'A course on Webpack and Babel, tools for modern web development. Learn to bundle and transpile JavaScript, manage assets, and optimize your application for production.',
    uses: [
      'Bundling and optimizing JavaScript code',
      'Transpiling modern JavaScript for compatibility',
      'Streamlining development workflows',
    ],
  },
  {
    name: 'Problem-Solving-Using-JavaScript',
    description:
      'A course designed to enhance problem-solving skills using JavaScript. Learn to break down problems, design algorithms, and implement solutions effectively.',
    uses: [
      'Developing algorithmic thinking',
      'Building efficient JavaScript solutions',
      'Foundation for competitive programming and real-world applications',
    ],
  },
];

export const bTechTopics = [
  {
    name: 'PROGRAMMING FOR PROBLEM SOLVING',
    description:
      'An introductory course focused on developing problem-solving skills using programming. It emphasizes algorithms, flowcharts, and coding techniques to solve real-world computational problems.',
    uses: [
      'Algorithm Development',
      'Problem Analysis and Solution Design',
      'Foundation for Advanced Programming Languages',
    ],
  },
];

export const filesParentPath = {
  html: 'JobSkills/HTML',
  css: 'JobSkills/CSS',
  'javascript-es6': 'JobSkills/JavaScript-(ES6+)',
  'react-js': 'JobSkills/React.js',
  'responsive-design-react-js': 'JobSkills/Responsive-Design-(React.js)',
  'node-js': 'JobSkills/Node.js',
  'express-js': 'JobSkills/Express.js',
  mongodb: 'JobSkills/MongoDB',
  postgresql: 'JobSkills/PostgreSQL',
  'asynchronous-programming-in-javascript':
    'JobSkills/Asynchronous-Programming-In-JavaScript',
  git: 'JobSkills/Git',
  'npm-yarn': 'JobSkills/NPM_Yarn',
  'webpack-babel': 'JobSkills/Webpack_Babel',
  'problem-solving-using-javascript':
    'JobSkills/Problem-Solving-Using-JavaScript',

  JavaScript: 'JavaScript',
  'PROGRAMMING FOR PROBLEM SOLVING': 'BTech/PROGRAMMING-FOR-PROBLEM-SOLVING',
};

export const jsConcepts = [
  {
    order: 1,
    name: 'UNIT - I: JavaScript Basics',
    sections: [
      {
        order: 1,
        name: '1.1 Fundamentals',
        topics: [
          { order: 1, name: 'Variables', topic_id: '1.1.1' },
          { order: 2, name: 'Data Types', topic_id: '1.1.2' },
          { order: 3, name: 'Operators', topic_id: '1.1.3' },
          { order: 4, name: 'Conditionals', topic_id: '1.1.4' },
          { order: 5, name: 'Statements', topic_id: '1.1.5' },
        ],
      },
      {
        order: 2,
        name: '1.2 Loops and Functions',
        topics: [
          { order: 6, name: 'Loops', topic_id: '1.2.1' },
          { order: 7, name: 'Functions', topic_id: '1.2.2' },
          { order: 8, name: 'Variable Scope', topic_id: '1.2.3' },
          { order: 9, name: 'this', topic_id: '1.2.4' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Advanced JavaScript',
    sections: [
      {
        order: 1,
        name: '2.1 Data Structures',
        topics: [
          { order: 10, name: 'Arrays', topic_id: '2.1.1' },
          { order: 11, name: 'Methods', topic_id: '2.1.2' },
          { order: 12, name: 'Mutators', topic_id: '2.1.3' },
          { order: 13, name: 'Sort', topic_id: '2.1.4' },
          { order: 14, name: 'Substring', topic_id: '2.1.5' },
          { order: 15, name: 'Strings', topic_id: '2.1.6' },
        ],
      },
      {
        order: 2,
        name: '2.2 Objects and Access',
        topics: [
          { order: 16, name: 'Objects', topic_id: '2.2.1' },
          { order: 17, name: 'Accessors', topic_id: '2.2.2' },
          { order: 18, name: 'Constructors', topic_id: '2.2.3' },
          { order: 19, name: 'Map', topic_id: '2.2.4' },
          { order: 20, name: 'Sets', topic_id: '2.2.5' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Functions and Asynchronous JavaScript',
    sections: [
      {
        order: 1,
        name: '3.1 Function Concepts',
        topics: [
          { order: 21, name: 'Hoisting', topic_id: '3.1.1' },
          { order: 22, name: 'Arrow Functions', topic_id: '3.1.2' },
          { order: 23, name: 'Callbacks', topic_id: '3.1.3' },
          { order: 24, name: 'Closures', topic_id: '3.1.4' },
        ],
      },
      {
        order: 2,
        name: '3.2 Asynchronous JavaScript',
        topics: [
          { order: 25, name: 'Events', topic_id: '3.2.1' },
          { order: 26, name: 'JSON', topic_id: '3.2.2' },
          { order: 27, name: 'AJAX', topic_id: '3.2.3' },
          { order: 28, name: 'Promise', topic_id: '3.2.4' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Modules and Error Handling',
    sections: [
      {
        order: 1,
        name: '4.1 Modules and Tools',
        topics: [
          { order: 29, name: 'Modules', topic_id: '4.1.1' },
          { order: 30, name: 'npm', topic_id: '4.1.2' },
        ],
      },
      {
        order: 2,
        name: '4.2 Error Handling',
        topics: [
          { order: 31, name: 'Dates', topic_id: '4.2.1' },
          { order: 32, name: 'Errors', topic_id: '4.2.2' },
          { order: 33, name: 'Try/Catch', topic_id: '4.2.3' },
          { order: 34, name: 'Type Coercion', topic_id: '4.2.4' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Advanced Concepts',
    sections: [
      {
        order: 1,
        name: '5.1 Storage and Optimization',
        topics: [
          { order: 35, name: 'Storage', topic_id: '5.1.1' },
          { order: 36, name: 'Hash Tables', topic_id: '5.1.2' },
          { order: 37, name: 'Memoization', topic_id: '5.1.3' },
        ],
      },
      {
        order: 2,
        name: '5.2 Iterators and Other Advanced Topics',
        topics: [
          { order: 38, name: 'Iterators', topic_id: '5.2.1' },
          { order: 39, name: 'Rest Parameters', topic_id: '5.2.2' },
          { order: 40, name: 'Enums', topic_id: '5.2.3' },
          { order: 41, name: 'RegExp', topic_id: '5.2.4' },
          { order: 42, name: 'Bitwise Operators', topic_id: '5.2.5' },
        ],
      },
      {
        order: 3,
        name: '5.3 Miscellaneous Topics',
        topics: [
          { order: 43, name: 'Requests', topic_id: '5.3.1' },
          { order: 44, name: 'window', topic_id: '5.3.2' },
          { order: 45, name: 'jQuery', topic_id: '5.3.3' },
        ],
      },
    ],
  },
];

export const htmlConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to HTML',
    sections: [
      {
        order: 1,
        name: '1.1 Basics',
        topics: [
          { order: 1, name: 'What is HTML?', topic_id: '1.1.1' },
          { order: 2, name: 'Setting Up the Environment', topic_id: '1.1.2' },
          { order: 3, name: 'Your First HTML File', topic_id: '1.1.3' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: HTML Document Structure',
    sections: [
      {
        order: 1,
        name: '2.1 Document Structure',
        topics: [
          { order: 4, name: '<!DOCTYPE> Declaration', topic_id: '2.1.1' },
          { order: 5, name: '<html> Element', topic_id: '2.1.2' },
          { order: 6, name: '<head> Element', topic_id: '2.1.3' },
          { order: 7, name: '<body> Element', topic_id: '2.1.4' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Text Content and Formatting',
    sections: [
      {
        order: 1,
        name: '3.1 Formatting Text',
        topics: [
          { order: 8, name: 'Headings', topic_id: '3.1.1' },
          { order: 9, name: 'Paragraphs', topic_id: '3.1.2' },
          {
            order: 10,
            name: 'Line Breaks and Horizontal Rules',
            topic_id: '3.1.3',
          },
          { order: 11, name: 'Text Emphasis', topic_id: '3.1.4' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Lists',
    sections: [
      {
        order: 1,
        name: '4.1 Creating Lists',
        topics: [
          { order: 12, name: 'Ordered Lists', topic_id: '4.1.1' },
          { order: 13, name: 'Unordered Lists', topic_id: '4.1.2' },
          { order: 14, name: 'Description Lists', topic_id: '4.1.3' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Links and Navigation',
    sections: [
      {
        order: 1,
        name: '5.1 Working with Links',
        topics: [
          { order: 15, name: 'Hyperlinks', topic_id: '5.1.1' },
          { order: 16, name: 'Anchor Links', topic_id: '5.1.2' },
          { order: 17, name: 'Email Links', topic_id: '5.1.3' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Images and Multimedia',
    sections: [
      {
        order: 1,
        name: '6.1 Adding Media',
        topics: [
          { order: 18, name: 'Adding Images', topic_id: '6.1.1' },
          { order: 19, name: 'Image Attributes', topic_id: '6.1.2' },
          { order: 20, name: 'Adding Videos', topic_id: '6.1.3' },
          { order: 21, name: 'Adding Audio', topic_id: '6.1.4' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Tables',
    sections: [
      {
        order: 1,
        name: '7.1 Working with Tables',
        topics: [
          { order: 22, name: 'Creating Tables', topic_id: '7.1.1' },
          { order: 23, name: 'Table Headers', topic_id: '7.1.2' },
          { order: 24, name: 'Spanning Rows and Columns', topic_id: '7.1.3' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Forms and User Input',
    sections: [
      {
        order: 1,
        name: '8.1 Building Forms',
        topics: [
          { order: 25, name: 'Form Structure', topic_id: '8.1.1' },
          { order: 26, name: 'Input Types', topic_id: '8.1.2' },
          { order: 27, name: 'Form Controls', topic_id: '8.1.3' },
          { order: 28, name: 'Form Validation', topic_id: '8.1.4' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Semantic HTML',
    sections: [
      {
        order: 1,
        name: '9.1 Semantic HTML',
        topics: [
          { order: 29, name: 'Semantic Elements', topic_id: '9.1.1' },
          { order: 30, name: 'Benefits of Semantics', topic_id: '9.1.2' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: HTML5 Features',
    sections: [
      {
        order: 1,
        name: '10.1 New Features',
        topics: [
          { order: 31, name: 'New Input Types', topic_id: '10.1.1' },
          { order: 32, name: 'Multimedia Elements', topic_id: '10.1.2' },
          { order: 33, name: 'Graphics', topic_id: '10.1.3' },
          { order: 34, name: 'Web Storage', topic_id: '10.1.4' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Best Practices',
    sections: [
      {
        order: 1,
        name: '11.1 Coding Practices',
        topics: [
          { order: 35, name: 'Writing Clean Code', topic_id: '11.1.1' },
          {
            order: 36,
            name: 'Cross-Browser Compatibility',
            topic_id: '11.1.2',
          },
          { order: 37, name: 'Accessibility', topic_id: '11.1.3' },
          { order: 38, name: 'SEO-Friendly Markup', topic_id: '11.1.4' },
        ],
      },
    ],
  },
];

export const cssConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to CSS',
    sections: [
      {
        order: 1,
        name: '1.1 CSS Basics',
        topics: [
          { order: 1, name: 'What is CSS?', topic_id: '1.1.1' },
          { order: 2, name: 'CSS Syntax', topic_id: '1.1.2' },
          { order: 3, name: 'Adding CSS to HTML', topic_id: '1.1.3' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Selectors',
    sections: [
      {
        order: 1,
        name: '2.1 Types of Selectors',
        topics: [
          { order: 4, name: 'Type Selectors', topic_id: '2.1.1' },
          { order: 5, name: 'Class Selectors', topic_id: '2.1.2' },
          { order: 6, name: 'ID Selectors', topic_id: '2.1.3' },
          { order: 7, name: 'Attribute Selectors', topic_id: '2.1.4' },
          { order: 8, name: 'Pseudo-classes', topic_id: '2.1.5' },
          { order: 9, name: 'Pseudo-elements', topic_id: '2.1.6' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Visual Rules',
    sections: [
      {
        order: 1,
        name: '3.1 Styling Elements',
        topics: [
          { order: 10, name: 'Color', topic_id: '3.1.1' },
          { order: 11, name: 'Text Alignment', topic_id: '3.1.2' },
          { order: 12, name: 'Font Styling', topic_id: '3.1.3' },
          {
            order: 13,
            name: 'Line Height and Letter Spacing',
            topic_id: '3.1.4',
          },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: The Box Model',
    sections: [
      {
        order: 1,
        name: '4.1 Box Model Properties',
        topics: [
          { order: 14, name: 'Content Area', topic_id: '4.1.1' },
          { order: 15, name: 'Padding', topic_id: '4.1.2' },
          { order: 16, name: 'Border', topic_id: '4.1.3' },
          { order: 17, name: 'Margin', topic_id: '4.1.4' },
          { order: 18, name: 'Box Sizing', topic_id: '4.1.5' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Display and Positioning',
    sections: [
      {
        order: 1,
        name: '5.1 Positioning Elements',
        topics: [
          { order: 19, name: 'Display Property', topic_id: '5.1.1' },
          { order: 20, name: 'Position Property', topic_id: '5.1.2' },
          { order: 21, name: 'Z-Index', topic_id: '5.1.3' },
          { order: 22, name: 'Float and Clear', topic_id: '5.1.4' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Flexbox',
    sections: [
      {
        order: 1,
        name: '6.1 Flexible Layouts',
        topics: [
          { order: 23, name: 'Flex Container', topic_id: '6.1.1' },
          { order: 24, name: 'Flex Items', topic_id: '6.1.2' },
          { order: 25, name: 'Flex Direction', topic_id: '6.1.3' },
          { order: 26, name: 'Justify Content', topic_id: '6.1.4' },
          { order: 27, name: 'Align Items', topic_id: '6.1.5' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Grid',
    sections: [
      {
        order: 1,
        name: '7.1 Grid Layouts',
        topics: [
          { order: 28, name: 'Grid Container', topic_id: '7.1.1' },
          { order: 29, name: 'Grid Items', topic_id: '7.1.2' },
          { order: 30, name: 'Grid Template Areas', topic_id: '7.1.3' },
          { order: 31, name: 'Gap Property', topic_id: '7.1.4' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Responsive Design',
    sections: [
      {
        order: 1,
        name: '8.1 Designing for Multiple Devices',
        topics: [
          { order: 32, name: 'Media Queries', topic_id: '8.1.1' },
          { order: 33, name: 'Relative Units', topic_id: '8.1.2' },
          { order: 34, name: 'Responsive Images', topic_id: '8.1.3' },
          { order: 35, name: 'Mobile-First Design', topic_id: '8.1.4' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Transitions and Animations',
    sections: [
      {
        order: 1,
        name: '9.1 Adding Motion',
        topics: [
          { order: 36, name: 'Transitions', topic_id: '9.1.1' },
          { order: 37, name: 'Keyframes', topic_id: '9.1.2' },
          { order: 38, name: 'Animation Property', topic_id: '9.1.3' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: CSS Variables',
    sections: [
      {
        order: 1,
        name: '10.1 Working with Variables',
        topics: [
          { order: 39, name: 'Defining Variables', topic_id: '10.1.1' },
          { order: 40, name: 'Using Variables', topic_id: '10.1.2' },
          { order: 41, name: 'Variable Scope', topic_id: '10.1.3' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Best Practices',
    sections: [
      {
        order: 1,
        name: '11.1 Optimizing CSS',
        topics: [
          { order: 42, name: 'Organizing CSS', topic_id: '11.1.1' },
          { order: 43, name: 'Commenting', topic_id: '11.1.2' },
          { order: 44, name: 'Browser Compatibility', topic_id: '11.1.3' },
          { order: 45, name: 'Performance Optimization', topic_id: '11.1.4' },
        ],
      },
    ],
  },
];

export const javascriptConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to JavaScript',
    sections: [
      {
        order: 1,
        name: '1.1 Basics',
        topics: [
          { order: 1, name: 'What is JavaScript?', topic_id: '1.1.1' },
          { order: 2, name: 'Setting Up the Environment', topic_id: '1.1.2' },
          {
            order: 3,
            name: 'Your First JavaScript Program',
            topic_id: '1.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Variables and Data Types',
    sections: [
      {
        order: 1,
        name: '2.1 Working with Variables',
        topics: [
          { order: 4, name: 'var, let, and const', topic_id: '2.1.1' },
          { order: 5, name: 'Primitive Data Types', topic_id: '2.1.2' },
          { order: 6, name: 'Complex Data Types', topic_id: '2.1.3' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Operators',
    sections: [
      {
        order: 1,
        name: '3.1 Types of Operators',
        topics: [
          { order: 7, name: 'Arithmetic Operators', topic_id: '3.1.1' },
          { order: 8, name: 'Comparison Operators', topic_id: '3.1.2' },
          { order: 9, name: 'Logical Operators', topic_id: '3.1.3' },
          { order: 10, name: 'Assignment Operators', topic_id: '3.1.4' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Control Structures',
    sections: [
      {
        order: 1,
        name: '4.1 Control Flow',
        topics: [
          { order: 11, name: 'Conditional Statements', topic_id: '4.1.1' },
          { order: 12, name: 'Switch Statements', topic_id: '4.1.2' },
          { order: 13, name: 'Loops', topic_id: '4.1.3' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Functions',
    sections: [
      {
        order: 1,
        name: '5.1 Function Concepts',
        topics: [
          { order: 14, name: 'Function Declarations', topic_id: '5.1.1' },
          { order: 15, name: 'Function Expressions', topic_id: '5.1.2' },
          { order: 16, name: 'Arrow Functions', topic_id: '5.1.3' },
          { order: 17, name: 'Parameters and Arguments', topic_id: '5.1.4' },
          { order: 18, name: 'Return Values', topic_id: '5.1.5' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Objects and Classes',
    sections: [
      {
        order: 1,
        name: '6.1 Object Basics',
        topics: [
          { order: 19, name: 'Object Literals', topic_id: '6.1.1' },
          { order: 20, name: 'this Keyword', topic_id: '6.1.2' },
          { order: 21, name: 'Constructor Functions', topic_id: '6.1.3' },
          { order: 22, name: 'Classes', topic_id: '6.1.4' },
          { order: 23, name: 'Inheritance', topic_id: '6.1.5' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Arrays',
    sections: [
      {
        order: 1,
        name: '7.1 Array Operations',
        topics: [
          { order: 24, name: 'Array Methods', topic_id: '7.1.1' },
          { order: 25, name: 'Iterating Arrays', topic_id: '7.1.2' },
          { order: 26, name: 'Spread and Rest Operators', topic_id: '7.1.3' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: ES6+ Features',
    sections: [
      {
        order: 1,
        name: '8.1 Modern JavaScript Features',
        topics: [
          { order: 27, name: 'Template Literals', topic_id: '8.1.1' },
          { order: 28, name: 'Destructuring Assignment', topic_id: '8.1.2' },
          { order: 29, name: 'Modules', topic_id: '8.1.3' },
          { order: 30, name: 'Promises', topic_id: '8.1.4' },
          { order: 31, name: 'Async/Await', topic_id: '8.1.5' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Error Handling',
    sections: [
      {
        order: 1,
        name: '9.1 Handling Errors',
        topics: [
          { order: 32, name: 'try...catch Statements', topic_id: '9.1.1' },
          { order: 33, name: 'Throwing Errors', topic_id: '9.1.2' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Asynchronous JavaScript',
    sections: [
      {
        order: 1,
        name: '10.1 Working Asynchronously',
        topics: [
          { order: 34, name: 'Callbacks', topic_id: '10.1.1' },
          { order: 35, name: 'Event Loop', topic_id: '10.1.2' },
          { order: 36, name: 'Fetch API', topic_id: '10.1.3' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: DOM Manipulation',
    sections: [
      {
        order: 1,
        name: '11.1 Manipulating the DOM',
        topics: [
          { order: 37, name: 'Selecting Elements', topic_id: '11.1.1' },
          { order: 38, name: 'Modifying Content', topic_id: '11.1.2' },
          { order: 39, name: 'Event Listeners', topic_id: '11.1.3' },
          {
            order: 40,
            name: 'Creating and Removing Elements',
            topic_id: '11.1.4',
          },
        ],
      },
    ],
  },
  {
    order: 12,
    name: 'UNIT - XII: Best Practices',
    sections: [
      {
        order: 1,
        name: '12.1 Coding Principles',
        topics: [
          { order: 41, name: 'Writing Clean Code', topic_id: '12.1.1' },
          { order: 42, name: 'Debugging', topic_id: '12.1.2' },
          { order: 43, name: 'Performance Optimization', topic_id: '12.1.3' },
          { order: 44, name: 'Browser Compatibility', topic_id: '12.1.4' },
        ],
      },
    ],
  },
];

export const reactConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to React.js',
    sections: [
      {
        order: 1,
        name: '1.1 Basics',
        topics: [
          { order: 1, name: 'What is React?', topic_id: '1.1.1' },
          { order: 2, name: 'Advantages of React', topic_id: '1.1.2' },
          { order: 3, name: 'Setting Up the Environment', topic_id: '1.1.3' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: JSX (JavaScript XML)',
    sections: [
      {
        order: 1,
        name: '2.1 Understanding JSX',
        topics: [
          { order: 4, name: 'Understanding JSX', topic_id: '2.1.1' },
          { order: 5, name: 'Embedding Expressions', topic_id: '2.1.2' },
          { order: 6, name: 'JSX Attributes', topic_id: '2.1.3' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Components',
    sections: [
      {
        order: 1,
        name: '3.1 Building Components',
        topics: [
          { order: 7, name: 'Functional Components', topic_id: '3.1.1' },
          { order: 8, name: 'Class Components', topic_id: '3.1.2' },
          { order: 9, name: 'Props', topic_id: '3.1.3' },
          { order: 10, name: 'State', topic_id: '3.1.4' },
          { order: 11, name: 'Lifecycle Methods', topic_id: '3.1.5' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Event Handling',
    sections: [
      {
        order: 1,
        name: '4.1 Handling Events',
        topics: [
          { order: 12, name: 'Handling Events', topic_id: '4.1.1' },
          { order: 13, name: 'Synthetic Events', topic_id: '4.1.2' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Conditional Rendering',
    sections: [
      {
        order: 1,
        name: '5.1 Rendering Conditions',
        topics: [
          { order: 14, name: 'Conditional Rendering', topic_id: '5.1.1' },
          {
            order: 15,
            name: 'Inline Conditional Rendering',
            topic_id: '5.1.2',
          },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Lists and Keys',
    sections: [
      {
        order: 1,
        name: '6.1 Working with Lists',
        topics: [
          { order: 16, name: 'Rendering Lists', topic_id: '6.1.1' },
          { order: 17, name: 'Keys', topic_id: '6.1.2' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Forms and Controlled Components',
    sections: [
      {
        order: 1,
        name: '7.1 Handling Forms',
        topics: [
          { order: 18, name: 'Handling Forms', topic_id: '7.1.1' },
          { order: 19, name: 'Controlled Components', topic_id: '7.1.2' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Routing',
    sections: [
      {
        order: 1,
        name: '8.1 Navigation',
        topics: [
          { order: 20, name: 'React Router', topic_id: '8.1.1' },
          { order: 21, name: 'Nested Routes', topic_id: '8.1.2' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: State Management',
    sections: [
      {
        order: 1,
        name: '9.1 Managing State',
        topics: [
          { order: 22, name: 'Lifting State Up', topic_id: '9.1.1' },
          { order: 23, name: 'Context API', topic_id: '9.1.2' },
          { order: 24, name: 'Redux', topic_id: '9.1.3' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Hooks',
    sections: [
      {
        order: 1,
        name: '10.1 React Hooks',
        topics: [
          { order: 25, name: 'Introduction to Hooks', topic_id: '10.1.1' },
          { order: 26, name: 'useState Hook', topic_id: '10.1.2' },
          { order: 27, name: 'useEffect Hook', topic_id: '10.1.3' },
          { order: 28, name: 'Custom Hooks', topic_id: '10.1.4' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Performance Optimization',
    sections: [
      {
        order: 1,
        name: '11.1 Enhancing Performance',
        topics: [
          { order: 29, name: 'Memoization', topic_id: '11.1.1' },
          { order: 30, name: 'Code Splitting', topic_id: '11.1.2' },
        ],
      },
    ],
  },
  {
    order: 12,
    name: 'UNIT - XII: Testing',
    sections: [
      {
        order: 1,
        name: '12.1 Testing React Applications',
        topics: [
          { order: 31, name: 'Unit Testing', topic_id: '12.1.1' },
          { order: 32, name: 'Integration Testing', topic_id: '12.1.2' },
        ],
      },
    ],
  },
  {
    order: 13,
    name: 'UNIT - XIII: Deployment',
    sections: [
      {
        order: 1,
        name: '13.1 Preparing for Production',
        topics: [
          { order: 33, name: 'Building for Production', topic_id: '13.1.1' },
          { order: 34, name: 'Deployment Platforms', topic_id: '13.1.2' },
        ],
      },
    ],
  },
];

export const responsiveDesignConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Responsive Design',
    sections: [
      {
        order: 1,
        name: '1.1 Basics',
        topics: [
          { order: 1, name: 'What is Responsive Design?', topic_id: '1.1.1' },
          { order: 2, name: 'Responsive Design in React', topic_id: '1.1.2' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up the Environment',
    sections: [
      {
        order: 1,
        name: '2.1 Preparing the Environment',
        topics: [
          { order: 3, name: 'Create React App', topic_id: '2.1.1' },
          { order: 4, name: 'Installing Dependencies', topic_id: '2.1.2' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: CSS Frameworks and Libraries',
    sections: [
      {
        order: 1,
        name: '3.1 Styling Libraries',
        topics: [
          { order: 5, name: 'Tailwind CSS', topic_id: '3.1.1' },
          { order: 6, name: 'Bootstrap', topic_id: '3.1.2' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Media Queries in React',
    sections: [
      {
        order: 1,
        name: '4.1 Implementing Media Queries',
        topics: [
          { order: 7, name: 'CSS Media Queries', topic_id: '4.1.1' },
          { order: 8, name: 'react-responsive Library', topic_id: '4.1.2' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Responsive Layout Techniques',
    sections: [
      {
        order: 1,
        name: '5.1 Layout Strategies',
        topics: [
          { order: 9, name: 'Flexbox', topic_id: '5.1.1' },
          { order: 10, name: 'CSS Grid', topic_id: '5.1.2' },
          { order: 11, name: 'Responsive Units', topic_id: '5.1.3' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Responsive Images and Media',
    sections: [
      {
        order: 1,
        name: '6.1 Optimizing Media',
        topics: [
          { order: 12, name: 'Image Optimization', topic_id: '6.1.1' },
          { order: 13, name: 'Media Queries for Images', topic_id: '6.1.2' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Testing Responsive Design',
    sections: [
      {
        order: 1,
        name: '7.1 Testing Tools',
        topics: [
          { order: 14, name: 'Browser Developer Tools', topic_id: '7.1.1' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Best Practices',
    sections: [
      {
        order: 1,
        name: '8.1 Optimization and Design Principles',
        topics: [
          { order: 15, name: 'Online Testing Platforms', topic_id: '8.1.1' },
          { order: 16, name: 'Mobile-First Design', topic_id: '8.1.2' },
          {
            order: 17,
            name: 'Accessibility Considerations',
            topic_id: '8.1.3',
          },
          { order: 18, name: 'Performance Optimization', topic_id: '8.1.4' },
        ],
      },
    ],
  },
];

export const nodejsConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Node.js',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of Node.js',
        topics: [
          { order: 1, name: 'What is Node.js?', topic_id: '1.1.1' },
          { order: 2, name: 'Advantages of Node.js', topic_id: '1.1.2' },
          {
            order: 3,
            name: 'Traditional Web Server Model vs. Node.js',
            topic_id: '1.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up the Environment',
    sections: [
      {
        order: 1,
        name: '2.1 Environment Setup',
        topics: [
          { order: 4, name: 'Installing Node.js', topic_id: '2.1.1' },
          { order: 5, name: 'Node.js Console (REPL)', topic_id: '2.1.2' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Node.js Modules',
    sections: [
      {
        order: 1,
        name: '3.1 Understanding Modules',
        topics: [
          { order: 6, name: 'Core Modules', topic_id: '3.1.1' },
          { order: 7, name: 'Creating Custom Modules', topic_id: '3.1.2' },
          { order: 8, name: 'Module Exports', topic_id: '3.1.3' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Node Package Manager (NPM)',
    sections: [
      {
        order: 1,
        name: '4.1 Managing Packages',
        topics: [
          { order: 9, name: 'What is NPM?', topic_id: '4.1.1' },
          { order: 10, name: 'Installing Packages Locally', topic_id: '4.1.2' },
          {
            order: 11,
            name: 'Installing Packages Globally',
            topic_id: '4.1.3',
          },
          { order: 12, name: 'Managing package.json', topic_id: '4.1.4' },
          { order: 13, name: 'Updating Packages', topic_id: '4.1.5' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Creating a Web Server',
    sections: [
      {
        order: 1,
        name: '5.1 Web Server Basics',
        topics: [
          { order: 14, name: 'HTTP Module', topic_id: '5.1.1' },
          { order: 15, name: 'Handling HTTP Requests', topic_id: '5.1.2' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: File System Operations',
    sections: [
      {
        order: 1,
        name: '6.1 Working with the File System',
        topics: [
          { order: 16, name: 'Reading Files', topic_id: '6.1.1' },
          { order: 17, name: 'Writing Files', topic_id: '6.1.2' },
          { order: 18, name: 'Deleting Files', topic_id: '6.1.3' },
          { order: 19, name: 'Other I/O Operations', topic_id: '6.1.4' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Debugging Node.js Applications',
    sections: [
      {
        order: 1,
        name: '7.1 Debugging Techniques',
        topics: [
          { order: 20, name: 'Debugging Techniques', topic_id: '7.1.1' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Event-Driven Programming',
    sections: [
      {
        order: 1,
        name: '8.1 Understanding Events',
        topics: [
          { order: 21, name: 'Event Emitters', topic_id: '8.1.1' },
          { order: 22, name: 'Inheriting Events', topic_id: '8.1.2' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Express.js Framework',
    sections: [
      {
        order: 1,
        name: '9.1 Building with Express.js',
        topics: [
          { order: 23, name: 'Introduction to Express.js', topic_id: '9.1.1' },
          { order: 24, name: 'Setting Up Express.js', topic_id: '9.1.2' },
          { order: 25, name: 'Routing', topic_id: '9.1.3' },
          { order: 26, name: 'Middleware', topic_id: '9.1.4' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Serving Static Resources',
    sections: [
      {
        order: 1,
        name: '10.1 Handling Static Files',
        topics: [{ order: 27, name: 'Static Files', topic_id: '10.1.1' }],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Database Connectivity',
    sections: [
      {
        order: 1,
        name: '11.1 Connecting Databases',
        topics: [
          { order: 28, name: 'Connecting to Databases', topic_id: '11.1.1' },
          { order: 29, name: 'Performing CRUD Operations', topic_id: '11.1.2' },
        ],
      },
    ],
  },
  {
    order: 12,
    name: 'UNIT - XII: Project Development',
    sections: [
      {
        order: 1,
        name: '12.1 Building Applications',
        topics: [
          { order: 30, name: 'Building Applications', topic_id: '12.1.1' },
        ],
      },
    ],
  },
];

export const expressConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Express.js',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of Express.js',
        topics: [
          { order: 1, name: 'What is Express.js?', topic_id: '1.1.1' },
          { order: 2, name: 'Advantages of Express.js', topic_id: '1.1.2' },
          {
            order: 3,
            name: 'Traditional Web Server Model vs. Express.js',
            topic_id: '1.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up the Environment',
    sections: [
      {
        order: 1,
        name: '2.1 Environment Setup',
        topics: [
          { order: 4, name: 'Installing Node.js and NPM', topic_id: '2.1.1' },
          { order: 5, name: 'Installing Express.js', topic_id: '2.1.2' },
          {
            order: 6,
            name: 'Setting Up a Basic Express Application',
            topic_id: '2.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Routing',
    sections: [
      {
        order: 1,
        name: '3.1 Implementing Routes',
        topics: [
          { order: 7, name: 'Basic Routing', topic_id: '3.1.1' },
          { order: 8, name: 'Route Parameters', topic_id: '3.1.2' },
          { order: 9, name: 'Query Parameters', topic_id: '3.1.3' },
          { order: 10, name: 'Handling 404 Errors', topic_id: '3.1.4' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Middleware',
    sections: [
      {
        order: 1,
        name: '4.1 Working with Middleware',
        topics: [
          { order: 11, name: 'What is Middleware?', topic_id: '4.1.1' },
          {
            order: 12,
            name: 'Application-Level Middleware',
            topic_id: '4.1.2',
          },
          { order: 13, name: 'Router-Level Middleware', topic_id: '4.1.3' },
          { order: 14, name: 'Error-Handling Middleware', topic_id: '4.1.4' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Request and Response Objects',
    sections: [
      {
        order: 1,
        name: '5.1 Understanding Requests and Responses',
        topics: [
          { order: 15, name: 'Request Object', topic_id: '5.1.1' },
          { order: 16, name: 'Response Object', topic_id: '5.1.2' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Template Engines',
    sections: [
      {
        order: 1,
        name: '6.1 Dynamic Content Rendering',
        topics: [
          {
            order: 17,
            name: 'Integrating Template Engines',
            topic_id: '6.1.1',
          },
          { order: 18, name: 'Rendering Dynamic Content', topic_id: '6.1.2' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Static Files',
    sections: [
      {
        order: 1,
        name: '7.1 Serving Static Content',
        topics: [
          { order: 19, name: 'Serving Static Files', topic_id: '7.1.1' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Form Handling',
    sections: [
      {
        order: 1,
        name: '8.1 Handling Form Data',
        topics: [
          { order: 20, name: 'Parsing Form Data', topic_id: '8.1.1' },
          { order: 21, name: 'File Uploads', topic_id: '8.1.2' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Database Integration',
    sections: [
      {
        order: 1,
        name: '9.1 Connecting Databases',
        topics: [
          { order: 22, name: 'Connecting to Databases', topic_id: '9.1.1' },
          { order: 23, name: 'Performing CRUD Operations', topic_id: '9.1.2' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Authentication and Authorization',
    sections: [
      {
        order: 1,
        name: '10.1 Securing Applications',
        topics: [
          { order: 24, name: 'User Authentication', topic_id: '10.1.1' },
          { order: 25, name: 'Protecting Routes', topic_id: '10.1.2' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Error Handling',
    sections: [
      {
        order: 1,
        name: '11.1 Handling Application Errors',
        topics: [{ order: 26, name: 'Handling Errors', topic_id: '11.1.1' }],
      },
    ],
  },
  {
    order: 12,
    name: 'UNIT - XII: Testing and Debugging',
    sections: [
      {
        order: 1,
        name: '12.1 Ensuring Code Quality',
        topics: [
          { order: 27, name: 'Writing Tests', topic_id: '12.1.1' },
          { order: 28, name: 'Debugging Techniques', topic_id: '12.1.2' },
        ],
      },
    ],
  },
  {
    order: 13,
    name: 'UNIT - XIII: Deployment',
    sections: [
      {
        order: 1,
        name: '13.1 Deploying Applications',
        topics: [
          { order: 29, name: 'Preparing for Deployment', topic_id: '13.1.1' },
          { order: 30, name: 'Deployment Platforms', topic_id: '13.1.2' },
        ],
      },
    ],
  },
];

export const mongodbConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to MongoDB',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of MongoDB',
        topics: [
          { order: 1, name: 'What is MongoDB?', topic_id: '1.1.1' },
          { order: 2, name: 'Advantages of MongoDB', topic_id: '1.1.2' },
          { order: 3, name: 'SQL vs. NoSQL Databases', topic_id: '1.1.3' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up the Environment',
    sections: [
      {
        order: 1,
        name: '2.1 Environment Setup',
        topics: [
          { order: 4, name: 'Installing MongoDB', topic_id: '2.1.1' },
          { order: 5, name: 'MongoDB Atlas', topic_id: '2.1.2' },
          { order: 6, name: 'MongoDB Shell (mongosh)', topic_id: '2.1.3' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Data Modeling',
    sections: [
      {
        order: 1,
        name: '3.1 Structuring Data',
        topics: [
          { order: 7, name: 'Documents and Collections', topic_id: '3.1.1' },
          { order: 8, name: 'Data Types', topic_id: '3.1.2' },
          { order: 9, name: 'Schema Design', topic_id: '3.1.3' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: CRUD Operations',
    sections: [
      {
        order: 1,
        name: '4.1 Performing CRUD Operations',
        topics: [
          { order: 10, name: 'Creating Documents', topic_id: '4.1.1' },
          { order: 11, name: 'Reading Documents', topic_id: '4.1.2' },
          { order: 12, name: 'Updating Documents', topic_id: '4.1.3' },
          { order: 13, name: 'Deleting Documents', topic_id: '4.1.4' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Indexes',
    sections: [
      {
        order: 1,
        name: '5.1 Working with Indexes',
        topics: [
          { order: 14, name: 'Creating Indexes', topic_id: '5.1.1' },
          { order: 15, name: 'Types of Indexes', topic_id: '5.1.2' },
          { order: 16, name: 'Managing Indexes', topic_id: '5.1.3' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Aggregation Framework',
    sections: [
      {
        order: 1,
        name: '6.1 Aggregating Data',
        topics: [
          { order: 17, name: 'Aggregation Pipeline', topic_id: '6.1.1' },
          { order: 18, name: 'Map-Reduce', topic_id: '6.1.2' },
          {
            order: 19,
            name: 'Single-Purpose Aggregation Methods',
            topic_id: '6.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Replication',
    sections: [
      {
        order: 1,
        name: '7.1 High Availability with Replication',
        topics: [
          { order: 20, name: 'Replica Sets', topic_id: '7.1.1' },
          { order: 21, name: 'Members Roles', topic_id: '7.1.2' },
          { order: 22, name: 'Failover Process', topic_id: '7.1.3' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Sharding',
    sections: [
      {
        order: 1,
        name: '8.1 Distributing Data',
        topics: [
          { order: 23, name: 'Shard Keys', topic_id: '8.1.1' },
          { order: 24, name: 'Sharded Clusters', topic_id: '8.1.2' },
          { order: 25, name: 'Balancing Data', topic_id: '8.1.3' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Transactions',
    sections: [
      {
        order: 1,
        name: '9.1 Managing Transactions',
        topics: [
          { order: 26, name: 'ACID Transactions', topic_id: '9.1.1' },
          { order: 27, name: 'Session Management', topic_id: '9.1.2' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Security',
    sections: [
      {
        order: 1,
        name: '10.1 Securing Data',
        topics: [
          { order: 28, name: 'Authentication', topic_id: '10.1.1' },
          { order: 29, name: 'Authorization', topic_id: '10.1.2' },
          { order: 30, name: 'Encryption', topic_id: '10.1.3' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Backup and Restore',
    sections: [
      {
        order: 1,
        name: '11.1 Data Recovery',
        topics: [
          { order: 31, name: 'Backup Strategies', topic_id: '11.1.1' },
          { order: 32, name: 'Restore Procedures', topic_id: '11.1.2' },
        ],
      },
    ],
  },
  {
    order: 12,
    name: 'UNIT - XII: Performance Tuning',
    sections: [
      {
        order: 1,
        name: '12.1 Optimizing Performance',
        topics: [
          { order: 33, name: 'Query Optimization', topic_id: '12.1.1' },
          { order: 34, name: 'Monitoring Tools', topic_id: '12.1.2' },
        ],
      },
    ],
  },
  {
    order: 13,
    name: 'UNIT - XIII: Integration with Applications',
    sections: [
      {
        order: 1,
        name: '13.1 Connecting Applications',
        topics: [
          { order: 35, name: 'MongoDB Drivers', topic_id: '13.1.1' },
          { order: 36, name: 'ODM/ORM Tools', topic_id: '13.1.2' },
        ],
      },
    ],
  },
  {
    order: 14,
    name: 'UNIT - XIV: Advanced Topics',
    sections: [
      {
        order: 1,
        name: '14.1 Exploring Advanced Features',
        topics: [
          { order: 37, name: 'GridFS', topic_id: '14.1.1' },
          { order: 38, name: 'Geospatial Data', topic_id: '14.1.2' },
          { order: 39, name: 'Time Series Data', topic_id: '14.1.3' },
        ],
      },
    ],
  },
];

export const postgresqlConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to PostgreSQL',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of PostgreSQL',
        topics: [
          { order: 1, name: 'What is PostgreSQL?', topic_id: '1.1.1' },
          { order: 2, name: 'Advantages of PostgreSQL', topic_id: '1.1.2' },
          { order: 3, name: 'SQL vs. NoSQL Databases', topic_id: '1.1.3' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up the Environment',
    sections: [
      {
        order: 1,
        name: '2.1 Environment Setup',
        topics: [
          { order: 4, name: 'Installing PostgreSQL', topic_id: '2.1.1' },
          { order: 5, name: 'PostgreSQL Tools', topic_id: '2.1.2' },
          { order: 6, name: 'Creating a Database', topic_id: '2.1.3' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Data Types',
    sections: [
      {
        order: 1,
        name: '3.1 Understanding Data Types',
        topics: [
          { order: 7, name: 'Numeric Types', topic_id: '3.1.1' },
          { order: 8, name: 'Character Types', topic_id: '3.1.2' },
          { order: 9, name: 'Date/Time Types', topic_id: '3.1.3' },
          { order: 10, name: 'Boolean Type', topic_id: '3.1.4' },
          { order: 11, name: 'JSON and JSONB Types', topic_id: '3.1.5' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Schema Management',
    sections: [
      {
        order: 1,
        name: '4.1 Managing Schemas',
        topics: [
          { order: 12, name: 'Creating Tables', topic_id: '4.1.1' },
          { order: 13, name: 'Modifying Tables', topic_id: '4.1.2' },
          { order: 14, name: 'Dropping Tables', topic_id: '4.1.3' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Constraints',
    sections: [
      {
        order: 1,
        name: '5.1 Applying Constraints',
        topics: [
          { order: 15, name: 'Primary Keys', topic_id: '5.1.1' },
          { order: 16, name: 'Foreign Keys', topic_id: '5.1.2' },
          { order: 17, name: 'Unique Constraints', topic_id: '5.1.3' },
          { order: 18, name: 'Check Constraints', topic_id: '5.1.4' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: CRUD Operations',
    sections: [
      {
        order: 1,
        name: '6.1 Basic Operations',
        topics: [
          { order: 19, name: 'Inserting Data', topic_id: '6.1.1' },
          { order: 20, name: 'Querying Data', topic_id: '6.1.2' },
          { order: 21, name: 'Updating Data', topic_id: '6.1.3' },
          { order: 22, name: 'Deleting Data', topic_id: '6.1.4' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Joins and Set Operations',
    sections: [
      {
        order: 1,
        name: '7.1 Combining Data',
        topics: [
          { order: 23, name: 'Inner Join', topic_id: '7.1.1' },
          { order: 24, name: 'Left Join', topic_id: '7.1.2' },
          { order: 25, name: 'Right Join', topic_id: '7.1.3' },
          { order: 26, name: 'Full Join', topic_id: '7.1.4' },
          { order: 27, name: 'Union', topic_id: '7.1.5' },
          { order: 28, name: 'Intersect', topic_id: '7.1.6' },
          { order: 29, name: 'Except', topic_id: '7.1.7' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Indexes',
    sections: [
      {
        order: 1,
        name: '8.1 Working with Indexes',
        topics: [
          { order: 30, name: 'Creating Indexes', topic_id: '8.1.1' },
          { order: 31, name: 'Types of Indexes', topic_id: '8.1.2' },
          { order: 32, name: 'Managing Indexes', topic_id: '8.1.3' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Views',
    sections: [
      {
        order: 1,
        name: '9.1 Managing Views',
        topics: [
          { order: 33, name: 'Creating Views', topic_id: '9.1.1' },
          { order: 34, name: 'Updating Views', topic_id: '9.1.2' },
          { order: 35, name: 'Dropping Views', topic_id: '9.1.3' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Transactions',
    sections: [
      {
        order: 1,
        name: '10.1 Ensuring Data Integrity',
        topics: [
          { order: 36, name: 'ACID Properties', topic_id: '10.1.1' },
          { order: 37, name: 'Transaction Control', topic_id: '10.1.2' },
          { order: 38, name: 'Savepoints', topic_id: '10.1.3' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Stored Procedures and Functions',
    sections: [
      {
        order: 1,
        name: '11.1 Advanced Programming',
        topics: [
          { order: 39, name: 'Creating Functions', topic_id: '11.1.1' },
          { order: 40, name: 'Creating Procedures', topic_id: '11.1.2' },
          { order: 41, name: 'PL/pgSQL Language', topic_id: '11.1.3' },
        ],
      },
    ],
  },
  {
    order: 12,
    name: 'UNIT - XII: Triggers',
    sections: [
      {
        order: 1,
        name: '12.1 Automating Actions',
        topics: [
          { order: 42, name: 'Defining Triggers', topic_id: '12.1.1' },
          { order: 43, name: 'Trigger Functions', topic_id: '12.1.2' },
        ],
      },
    ],
  },
  {
    order: 13,
    name: 'UNIT - XIII: Backup and Restore',
    sections: [
      {
        order: 1,
        name: '13.1 Data Recovery',
        topics: [
          { order: 44, name: 'Backup Strategies', topic_id: '13.1.1' },
          { order: 45, name: 'Restoring Data', topic_id: '13.1.2' },
        ],
      },
    ],
  },
  {
    order: 14,
    name: 'UNIT - XIV: Security',
    sections: [
      {
        order: 1,
        name: '14.1 Securing Databases',
        topics: [
          { order: 46, name: 'Authentication Methods', topic_id: '14.1.1' },
          { order: 47, name: 'Roles and Permissions', topic_id: '14.1.2' },
          { order: 48, name: 'Data Encryption', topic_id: '14.1.3' },
        ],
      },
    ],
  },
  {
    order: 15,
    name: 'UNIT - XV: Performance Tuning',
    sections: [
      {
        order: 1,
        name: '15.1 Optimizing Performance',
        topics: [
          { order: 49, name: 'Query Optimization', topic_id: '15.1.1' },
          { order: 50, name: 'Vacuuming', topic_id: '15.1.2' },
          { order: 51, name: 'Monitoring Tools', topic_id: '15.1.3' },
        ],
      },
    ],
  },
  {
    order: 16,
    name: 'UNIT - XVI: Advanced Features',
    sections: [
      {
        order: 1,
        name: '16.1 Exploring Advanced Concepts',
        topics: [
          {
            order: 52,
            name: 'Common Table Expressions (CTEs)',
            topic_id: '16.1.1',
          },
          { order: 53, name: 'Window Functions', topic_id: '16.1.2' },
          { order: 54, name: 'Table Inheritance', topic_id: '16.1.3' },
          { order: 55, name: 'Partitioning', topic_id: '16.1.4' },
        ],
      },
    ],
  },
  {
    order: 17,
    name: 'UNIT - XVII: Integration with Applications',
    sections: [
      {
        order: 1,
        name: '17.1 Application Integration',
        topics: [
          { order: 56, name: 'PostgreSQL Drivers', topic_id: '17.1.1' },
          { order: 57, name: 'ORMs', topic_id: '17.1.2' },
        ],
      },
    ],
  },
  {
    order: 18,
    name: 'UNIT - XVIII: Replication and High Availability',
    sections: [
      {
        order: 1,
        name: '18.1 Ensuring Availability',
        topics: [
          { order: 58, name: 'Streaming Replication', topic_id: '18.1.1' },
          { order: 59, name: 'Logical Replication', topic_id: '18.1.2' },
          { order: 60, name: 'Failover and Recovery', topic_id: '18.1.3' },
        ],
      },
    ],
  },
];

export const asyncProgrammingConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Asynchronous Programming',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of Asynchronous Programming',
        topics: [
          {
            order: 1,
            name: 'What is Asynchronous Programming?',
            topic_id: '1.1.1',
          },
          {
            order: 2,
            name: 'Synchronous vs. Asynchronous Execution',
            topic_id: '1.1.2',
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Callbacks',
    sections: [
      {
        order: 1,
        name: '2.1 Working with Callbacks',
        topics: [
          { order: 3, name: 'Understanding Callbacks', topic_id: '2.1.1' },
          { order: 4, name: 'Callback Hell', topic_id: '2.1.2' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Promises',
    sections: [
      {
        order: 1,
        name: '3.1 Understanding Promises',
        topics: [
          { order: 5, name: 'Introduction to Promises', topic_id: '3.1.1' },
          { order: 6, name: 'Promise States', topic_id: '3.1.2' },
          { order: 7, name: 'Chaining Promises', topic_id: '3.1.3' },
          { order: 8, name: 'Error Handling in Promises', topic_id: '3.1.4' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Async/Await',
    sections: [
      {
        order: 1,
        name: '4.1 Using Async/Await',
        topics: [
          { order: 9, name: 'Introduction to Async/Await', topic_id: '4.1.1' },
          {
            order: 10,
            name: 'Error Handling with Try/Catch',
            topic_id: '4.1.2',
          },
          {
            order: 11,
            name: 'Sequential vs. Concurrent Execution',
            topic_id: '4.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Event Loop',
    sections: [
      {
        order: 1,
        name: '5.1 Understanding the Event Loop',
        topics: [
          {
            order: 12,
            name: 'Understanding the Event Loop',
            topic_id: '5.1.1',
          },
          { order: 13, name: 'Call Stack and Task Queue', topic_id: '5.1.2' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Asynchronous APIs',
    sections: [
      {
        order: 1,
        name: '6.1 Using Asynchronous APIs',
        topics: [
          { order: 14, name: 'setTimeout and setInterval', topic_id: '6.1.1' },
          { order: 15, name: 'Fetch API', topic_id: '6.1.2' },
          { order: 16, name: 'Web Workers', topic_id: '6.1.3' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Error Handling in Asynchronous Code',
    sections: [
      {
        order: 1,
        name: '7.1 Managing Errors',
        topics: [
          {
            order: 17,
            name: 'Handling Errors in Callbacks',
            topic_id: '7.1.1',
          },
          {
            order: 18,
            name: 'Handling Errors in Async/Await',
            topic_id: '7.1.2',
          },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Best Practices',
    sections: [
      {
        order: 1,
        name: '8.1 Writing Robust Asynchronous Code',
        topics: [
          { order: 19, name: 'Avoiding Callback Hell', topic_id: '8.1.1' },
          { order: 20, name: 'Graceful Error Handling', topic_id: '8.1.2' },
          { order: 21, name: 'Performance Considerations', topic_id: '8.1.3' },
        ],
      },
    ],
  },
];

export const gitConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Git',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of Git',
        topics: [
          { order: 1, name: 'What is Git?', topic_id: '1.1.1' },
          { order: 2, name: 'Advantages of Git', topic_id: '1.1.2' },
          { order: 3, name: 'Git vs. Other VCS', topic_id: '1.1.3' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up Git',
    sections: [
      {
        order: 1,
        name: '2.1 Installation and Configuration',
        topics: [
          { order: 4, name: 'Installing Git', topic_id: '2.1.1' },
          { order: 5, name: 'First-Time Git Setup', topic_id: '2.1.2' },
          { order: 6, name: 'Git Configuration Levels', topic_id: '2.1.3' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Basic Git Commands',
    sections: [
      {
        order: 1,
        name: '3.1 Getting Started with Git',
        topics: [
          { order: 7, name: 'Initializing a Repository', topic_id: '3.1.1' },
          { order: 8, name: 'Cloning a Repository', topic_id: '3.1.2' },
          { order: 9, name: 'Checking Repository Status', topic_id: '3.1.3' },
          { order: 10, name: 'Adding Changes', topic_id: '3.1.4' },
          { order: 11, name: 'Committing Changes', topic_id: '3.1.5' },
          { order: 12, name: 'Viewing Commit History', topic_id: '3.1.6' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Branching and Merging',
    sections: [
      {
        order: 1,
        name: '4.1 Managing Branches',
        topics: [
          { order: 13, name: 'Creating Branches', topic_id: '4.1.1' },
          { order: 14, name: 'Switching Branches', topic_id: '4.1.2' },
          { order: 15, name: 'Merging Branches', topic_id: '4.1.3' },
          { order: 16, name: 'Resolving Merge Conflicts', topic_id: '4.1.4' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Remote Repositories',
    sections: [
      {
        order: 1,
        name: '5.1 Collaborating with Remote Repositories',
        topics: [
          { order: 17, name: 'Adding Remotes', topic_id: '5.1.1' },
          { order: 18, name: 'Fetching Changes', topic_id: '5.1.2' },
          { order: 19, name: 'Pulling Changes', topic_id: '5.1.3' },
          { order: 20, name: 'Pushing Changes', topic_id: '5.1.4' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Undoing Changes',
    sections: [
      {
        order: 1,
        name: '6.1 Correcting Mistakes',
        topics: [
          { order: 21, name: 'Unstaging Files', topic_id: '6.1.1' },
          { order: 22, name: 'Reverting Commits', topic_id: '6.1.2' },
          { order: 23, name: 'Resetting Commits', topic_id: '6.1.3' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Stashing Changes',
    sections: [
      {
        order: 1,
        name: '7.1 Saving Work in Progress',
        topics: [
          { order: 24, name: 'Saving Unfinished Work', topic_id: '7.1.1' },
          { order: 25, name: 'Applying Stashed Changes', topic_id: '7.1.2' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Tagging',
    sections: [
      {
        order: 1,
        name: '8.1 Managing Tags',
        topics: [
          { order: 26, name: 'Creating Tags', topic_id: '8.1.1' },
          {
            order: 27,
            name: 'Annotated vs. Lightweight Tags',
            topic_id: '8.1.2',
          },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Git Workflows',
    sections: [
      {
        order: 1,
        name: '9.1 Common Workflows',
        topics: [
          { order: 28, name: 'Centralized Workflow', topic_id: '9.1.1' },
          { order: 29, name: 'Feature Branch Workflow', topic_id: '9.1.2' },
          { order: 30, name: 'Forking Workflow', topic_id: '9.1.3' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Advanced Git',
    sections: [
      {
        order: 1,
        name: '10.1 Advanced Features',
        topics: [
          { order: 31, name: 'Rebasing', topic_id: '10.1.1' },
          { order: 32, name: 'Cherry-Picking', topic_id: '10.1.2' },
          { order: 33, name: 'Submodules', topic_id: '10.1.3' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Git Tools',
    sections: [
      {
        order: 1,
        name: '11.1 Enhancing Git Usage',
        topics: [
          { order: 34, name: 'Git GUI Clients', topic_id: '11.1.1' },
          { order: 35, name: 'Git Hooks', topic_id: '11.1.2' },
          { order: 36, name: 'Git Attributes', topic_id: '11.1.3' },
        ],
      },
    ],
  },
  {
    order: 12,
    name: 'UNIT - XII: Best Practices',
    sections: [
      {
        order: 1,
        name: '12.1 Improving Git Workflows',
        topics: [
          {
            order: 37,
            name: 'Writing Good Commit Messages',
            topic_id: '12.1.1',
          },
          { order: 38, name: '.gitignore File', topic_id: '12.1.2' },
          { order: 39, name: 'Regularly Pulling Changes', topic_id: '12.1.3' },
        ],
      },
    ],
  },
];

export const packageManagerConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Package Managers',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of Package Managers',
        topics: [
          { order: 1, name: 'What is a Package Manager?', topic_id: '1.1.1' },
          { order: 2, name: 'Overview of NPM and Yarn', topic_id: '1.1.2' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up NPM and Yarn',
    sections: [
      {
        order: 1,
        name: '2.1 Installation',
        topics: [
          { order: 3, name: 'Installing Node.js and NPM', topic_id: '2.1.1' },
          { order: 4, name: 'Installing Yarn', topic_id: '2.1.2' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Initializing a Project',
    sections: [
      {
        order: 1,
        name: '3.1 Project Initialization',
        topics: [
          { order: 5, name: 'npm init', topic_id: '3.1.1' },
          { order: 6, name: 'yarn init', topic_id: '3.1.2' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Adding Dependencies',
    sections: [
      {
        order: 1,
        name: '4.1 Managing Dependencies',
        topics: [
          { order: 7, name: 'Installing Packages Locally', topic_id: '4.1.1' },
          { order: 8, name: 'Installing Packages Globally', topic_id: '4.1.2' },
          { order: 9, name: 'Development Dependencies', topic_id: '4.1.3' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Removing Dependencies',
    sections: [
      {
        order: 1,
        name: '5.1 Uninstalling Packages',
        topics: [
          { order: 10, name: 'Uninstalling Packages', topic_id: '5.1.1' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Updating Dependencies',
    sections: [
      {
        order: 1,
        name: '6.1 Keeping Dependencies Up-to-Date',
        topics: [
          { order: 11, name: 'Updating Packages', topic_id: '6.1.1' },
          { order: 12, name: 'Upgrading Specific Packages', topic_id: '6.1.2' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Managing Dependencies',
    sections: [
      {
        order: 1,
        name: '7.1 Dependency Files',
        topics: [
          { order: 13, name: 'package.json', topic_id: '7.1.1' },
          {
            order: 14,
            name: 'package-lock.json and yarn.lock',
            topic_id: '7.1.2',
          },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Running Scripts',
    sections: [
      {
        order: 1,
        name: '8.1 Scripts in Projects',
        topics: [
          { order: 15, name: 'Defining Scripts', topic_id: '8.1.1' },
          { order: 16, name: 'Executing Scripts', topic_id: '8.1.2' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Comparing NPM and Yarn',
    sections: [
      {
        order: 1,
        name: '9.1 Differences and Features',
        topics: [
          { order: 17, name: 'Performance', topic_id: '9.1.1' },
          { order: 18, name: 'Security Features', topic_id: '9.1.2' },
          { order: 19, name: 'Workspaces and Monorepos', topic_id: '9.1.3' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Advanced Features',
    sections: [
      {
        order: 1,
        name: '10.1 Enhancing Package Management',
        topics: [
          { order: 20, name: 'Caching', topic_id: '10.1.1' },
          { order: 21, name: "Plug'n'Play (PnP)", topic_id: '10.1.2' },
          { order: 22, name: 'NPM Audit', topic_id: '10.1.3' },
        ],
      },
    ],
  },
  {
    order: 11,
    name: 'UNIT - XI: Best Practices',
    sections: [
      {
        order: 1,
        name: '11.1 Effective Package Management',
        topics: [
          { order: 23, name: 'Lock Files', topic_id: '11.1.1' },
          { order: 24, name: 'Semantic Versioning', topic_id: '11.1.2' },
          { order: 25, name: 'Regular Updates', topic_id: '11.1.3' },
        ],
      },
    ],
  },
];

export const webpackBabelConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Webpack and Babel',
    sections: [
      {
        order: 1,
        name: '1.1 Basics of Webpack and Babel',
        topics: [
          { order: 1, name: 'What is Webpack?', topic_id: '1.1.1' },
          { order: 2, name: 'What is Babel?', topic_id: '1.1.2' },
          {
            order: 3,
            name: 'Benefits of Using Webpack and Babel Together',
            topic_id: '1.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Setting Up the Environment',
    sections: [
      {
        order: 1,
        name: '2.1 Environment Setup',
        topics: [
          { order: 4, name: 'Initializing the Project', topic_id: '2.1.1' },
          { order: 5, name: 'Installing Webpack', topic_id: '2.1.2' },
          { order: 6, name: 'Installing Babel', topic_id: '2.1.3' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Configuring Babel',
    sections: [
      {
        order: 1,
        name: '3.1 Babel Configuration',
        topics: [
          { order: 7, name: 'Creating .babelrc', topic_id: '3.1.1' },
          { order: 8, name: 'Presets and Plugins', topic_id: '3.1.2' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Configuring Webpack',
    sections: [
      {
        order: 1,
        name: '4.1 Webpack Configuration',
        topics: [
          { order: 9, name: 'Creating webpack.config.js', topic_id: '4.1.1' },
          { order: 10, name: 'Loaders', topic_id: '4.1.2' },
          { order: 11, name: 'Plugins', topic_id: '4.1.3' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Integrating Webpack and Babel',
    sections: [
      {
        order: 1,
        name: '5.1 Combining Webpack and Babel',
        topics: [
          {
            order: 12,
            name: 'Entry and Output Configuration',
            topic_id: '5.1.1',
          },
          { order: 13, name: 'Module Rules', topic_id: '5.1.2' },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Handling Different File Types',
    sections: [
      {
        order: 1,
        name: '6.1 Working with Assets',
        topics: [
          { order: 14, name: 'CSS and Style Loaders', topic_id: '6.1.1' },
          { order: 15, name: 'Asset Management', topic_id: '6.1.2' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Development Tools',
    sections: [
      {
        order: 1,
        name: '7.1 Tools for Development',
        topics: [
          { order: 16, name: 'Source Maps', topic_id: '7.1.1' },
          { order: 17, name: 'Development Server', topic_id: '7.1.2' },
        ],
      },
    ],
  },
  {
    order: 8,
    name: 'UNIT - VIII: Optimizations',
    sections: [
      {
        order: 1,
        name: '8.1 Performance Optimization',
        topics: [
          { order: 18, name: 'Code Splitting', topic_id: '8.1.1' },
          { order: 19, name: 'Tree Shaking', topic_id: '8.1.2' },
        ],
      },
    ],
  },
  {
    order: 9,
    name: 'UNIT - IX: Advanced Features',
    sections: [
      {
        order: 1,
        name: '9.1 Advanced Capabilities',
        topics: [
          { order: 20, name: 'Hot Module Replacement', topic_id: '9.1.1' },
          { order: 21, name: 'Environment Variables', topic_id: '9.1.2' },
        ],
      },
    ],
  },
  {
    order: 10,
    name: 'UNIT - X: Best Practices',
    sections: [
      {
        order: 1,
        name: '10.1 Best Practices for Webpack and Babel',
        topics: [
          { order: 22, name: 'Performance Optimization', topic_id: '10.1.1' },
          { order: 23, name: 'Security Considerations', topic_id: '10.1.2' },
          { order: 24, name: 'Maintenance and Upgrades', topic_id: '10.1.3' },
        ],
      },
    ],
  },
];

export const problemSolvingJSConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Problem-Solving in JavaScript',
    sections: [
      {
        order: 1,
        name: '1.1 Understanding Problem-Solving',
        topics: [
          {
            order: 1,
            name: 'Importance of Problem-Solving Skills',
            topic_id: '1.1.1',
          },
          {
            order: 2,
            name: 'Common Problem-Solving Approaches',
            topic_id: '1.1.2',
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Understanding the Problem',
    sections: [
      {
        order: 1,
        name: '2.1 Problem Analysis',
        topics: [
          { order: 3, name: 'Analyzing Problem Statements', topic_id: '2.1.1' },
          {
            order: 4,
            name: 'Identifying Inputs and Outputs',
            topic_id: '2.1.2',
          },
          { order: 5, name: 'Constraints and Edge Cases', topic_id: '2.1.3' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Planning the Solution',
    sections: [
      {
        order: 1,
        name: '3.1 Developing a Plan',
        topics: [
          { order: 6, name: 'Breaking Down the Problem', topic_id: '3.1.1' },
          { order: 7, name: 'Pseudocode and Flowcharts', topic_id: '3.1.2' },
          {
            order: 8,
            name: 'Selecting Appropriate Data Structures',
            topic_id: '3.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Implementing the Solution',
    sections: [
      {
        order: 1,
        name: '4.1 Writing Effective Code',
        topics: [
          {
            order: 9,
            name: 'Writing Clean and Efficient Code',
            topic_id: '4.1.1',
          },
          { order: 10, name: 'Utilizing Built-in Methods', topic_id: '4.1.2' },
          {
            order: 11,
            name: 'Iterative vs. Recursive Approaches',
            topic_id: '4.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Testing and Debugging',
    sections: [
      {
        order: 1,
        name: '5.1 Ensuring Solution Accuracy',
        topics: [
          { order: 12, name: 'Writing Test Cases', topic_id: '5.1.1' },
          { order: 13, name: 'Debugging Techniques', topic_id: '5.1.2' },
          {
            order: 14,
            name: 'Handling Errors and Exceptions',
            topic_id: '5.1.3',
          },
        ],
      },
    ],
  },
  {
    order: 6,
    name: 'UNIT - VI: Optimizing the Solution',
    sections: [
      {
        order: 1,
        name: '6.1 Improving Performance',
        topics: [
          { order: 15, name: 'Time and Space Complexity', topic_id: '6.1.1' },
          { order: 16, name: 'Refactoring Code', topic_id: '6.1.2' },
          { order: 17, name: 'Memory Management', topic_id: '6.1.3' },
        ],
      },
    ],
  },
  {
    order: 7,
    name: 'UNIT - VII: Practice and Application',
    sections: [
      {
        order: 1,
        name: '7.1 Applying Problem-Solving Skills',
        topics: [
          { order: 18, name: 'Solving Coding Challenges', topic_id: '7.1.1' },
          { order: 19, name: 'Building Projects', topic_id: '7.1.2' },
          {
            order: 20,
            name: 'Participating in Coding Communities',
            topic_id: '7.1.3',
          },
        ],
      },
    ],
  },
];

export const ppsConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Programming and C Programming Language',
    sections: [
      {
        order: 1,
        name: '1.1 Introduction to Programming',
        topics: [
          {
            order: 1,
            name: 'Compilers, Compiling and Executing a Program',
            topic_id: '1.1.1',
          },
          { order: 2, name: 'Representation of Algorithm', topic_id: '1.1.2' },
          {
            order: 3,
            name: 'Algorithms: Roots of Quadratic Equations, Finding Min/Max of a Set, Checking Prime Numbers',
            topic_id: '1.1.3',
          },
          {
            order: 4,
            name: 'Flowchart/Pseudocode with Examples',
            topic_id: '1.1.4',
          },
          {
            order: 5,
            name: 'Program Design and Structured Programming',
            topic_id: '1.1.5',
          },
        ],
      },
      {
        order: 2,
        name: '1.2 Introduction to C Programming Language',
        topics: [
          {
            order: 6,
            name: 'Variables (Data Types and Space Requirements)',
            topic_id: '1.2.1',
          },
          {
            order: 7,
            name: 'Syntax and Logical Errors, Object and Executable Code',
            topic_id: '1.2.2',
          },
          {
            order: 8,
            name: 'Operators, Expressions, Precedence, Expression Evaluation',
            topic_id: '1.2.3',
          },
          {
            order: 9,
            name: 'Storage Classes: auto, extern, static, register',
            topic_id: '1.2.4',
          },
          { order: 10, name: 'Type Conversion', topic_id: '1.2.5' },
          {
            order: 11,
            name: 'The Main Method and Command Line Arguments',
            topic_id: '1.2.6',
          },
          {
            order: 12,
            name: 'Bitwise Operations: AND, OR, XOR, NOT',
            topic_id: '1.2.7',
          },
          {
            order: 13,
            name: 'Conditional Branching and Loops',
            topics: [
              {
                order: 13,
                name: 'Writing/Evaluating Conditionals: if, if-else, switch-case, Ternary Operator, goto',
                topic_id: '1.2.8.1',
              },
              {
                order: 14,
                name: 'Iteration: for, while, do-while Loops',
                topic_id: '1.2.8.2',
              },
            ],
          },
          {
            order: 15,
            name: 'I/O',
            topics: [
              {
                order: 15,
                name: 'Input/Output: scanf, printf, Formatted I/O',
                topic_id: '1.2.9.1',
              },
              {
                order: 16,
                name: 'Introduction to stdin, stdout, stderr',
                topic_id: '1.2.9.2',
              },
              {
                order: 17,
                name: 'Command Line Arguments',
                topic_id: '1.2.9.3',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Arrays, Strings, Structures, and Pointers',
    sections: [
      {
        order: 18,
        name: 'Arrays',
        topics: [
          {
            order: 18,
            name: 'One and Two-Dimensional Arrays, Creating, Accessing, and Manipulating Array Elements',
            topic_id: '2.1.1',
          },
        ],
      },
      {
        order: 19,
        name: 'Strings',
        topics: [
          {
            order: 19,
            name: 'Strings as Arrays of Characters',
            topic_id: '2.2.1',
          },
          {
            order: 20,
            name: 'Basic String Functions (strlen, strcat, strcpy, strstr, etc.)',
            topic_id: '2.2.2',
          },
          { order: 21, name: 'Arrays of Strings', topic_id: '2.2.3' },
        ],
      },
      {
        order: 22,
        name: 'Structures',
        topics: [
          {
            order: 22,
            name: 'Defining and Initializing Structures',
            topic_id: '2.3.1',
          },
          { order: 23, name: 'Unions', topic_id: '2.3.2' },
          { order: 24, name: 'Array of Structures', topic_id: '2.3.3' },
        ],
      },
      {
        order: 25,
        name: 'Pointers',
        topics: [
          {
            order: 25,
            name: 'Idea of Pointers, Defining Pointers',
            topic_id: '2.4.1',
          },
          {
            order: 26,
            name: 'Pointers to Arrays and Structures',
            topic_id: '2.4.2',
          },
          {
            order: 27,
            name: 'Self-Referential Structures and Linked Lists (No Implementation)',
            topic_id: '2.4.3',
          },
        ],
      },
      { order: 28, name: 'Enumeration Data Type', topic_id: '2.5.1' },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Preprocessor and File Handling in C',
    sections: [
      {
        order: 29,
        name: 'Preprocessor',
        topics: [
          {
            order: 29,
            name: 'Common Preprocessor Commands: include, define, undef, if, ifdef, ifndef',
            topic_id: '3.1.1',
          },
        ],
      },
      {
        order: 30,
        name: 'Files',
        topics: [
          { order: 30, name: 'Text and Binary Files', topic_id: '3.2.1' },
          {
            order: 31,
            name: 'Creating, Reading, and Writing Text/Binary Files',
            topic_id: '3.2.2',
          },
          {
            order: 32,
            name: 'Appending Data to Existing Files',
            topic_id: '3.2.3',
          },
          {
            order: 33,
            name: 'Writing/Reading Structures Using Binary Files',
            topic_id: '3.2.4',
          },
          {
            order: 34,
            name: 'Random Access: fseek, ftell, rewind',
            topic_id: '3.2.5',
          },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Function and Dynamic Memory Allocation',
    sections: [
      {
        order: 35,
        name: 'Functions',
        topics: [
          {
            order: 35,
            name: 'Designing Structured Programs',
            topic_id: '4.1.1',
          },
          {
            order: 36,
            name: 'Declaring Functions, Signature, Parameters, and Return Type',
            topic_id: '4.1.2',
          },
          {
            order: 37,
            name: 'Passing Parameters: Call by Value',
            topic_id: '4.1.3',
          },
          {
            order: 38,
            name: 'Passing Arrays and Pointers to Functions',
            topic_id: '4.1.4',
          },
          { order: 39, name: 'Idea of Call by Reference', topic_id: '4.1.5' },
          {
            order: 40,
            name: 'C Standard Functions and Libraries',
            topic_id: '4.1.6',
          },
        ],
      },
      {
        order: 41,
        name: 'Recursion',
        topics: [
          {
            order: 41,
            name: 'Simple Recursive Programs: Factorial, Fibonacci',
            topic_id: '4.2.1',
          },
          { order: 42, name: 'Limitations of Recursion', topic_id: '4.2.2' },
        ],
      },
      {
        order: 43,
        name: 'Dynamic Memory Allocation',
        topics: [
          {
            order: 43,
            name: 'Allocating/Freeing Memory, Allocating Arrays of Different Data Types',
            topic_id: '4.3.1',
          },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Searching and Sorting',
    sections: [
      {
        order: 44,
        name: 'Searching and Sorting',
        topics: [
          {
            order: 44,
            name: 'Linear and Binary Search Algorithms',
            topic_id: '5.1.1',
          },
          {
            order: 45,
            name: 'Sorting Algorithms: Bubble, Insertion, Selection',
            topic_id: '5.2.1',
          },
          {
            order: 46,
            name: 'Order of Complexity (Example Programs)',
            topic_id: '5.3.1',
          },
        ],
      },
    ],
  },
];
