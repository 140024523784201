import ProblemStatementFileDataLoader from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Array/ProblemStatementFileDataLoader.js';

const loadingProblemStatementData = (fileName) => {
  const ProblemStatementLoader = (props) => (
    <ProblemStatementFileDataLoader fileName={fileName} {...props} />
  );

  // Set display name explicitly
  ProblemStatementLoader.displayName = 'ProblemStatementLoader';

  return ProblemStatementLoader;
};

export const arrayEasyProblemStatements = (title, setComponent) => {
  console.log(`REACHED: arrayEasyProblemStatements`);
  if (title === 'Relative Ranks') {
    const fileName = '506RelativeRanksArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Flipping an Image') {
    const fileName = '832FlippinganImageArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Assign Cookies') {
    const fileName = '455AssignCookiesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Keyboard Row') {
    const fileName = '500KeyboardRowArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find Common Characters') {
    const fileName = '1002FindCommonCharactersArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Contains Duplicate II') {
    const fileName = '219ContainsDuplicateIIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Merge Sorted Array') {
    const fileName = '88MergeSortedArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Min Cost Climbing Stairs') {
    const fileName = '746MinCostClimbingStairsArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Single Number') {
    const fileName = '136SingleNumberArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Maximize Sum Of Array After K Negations') {
    const fileName = '1005MaximizeSumOfArrayAfterKNegationsArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Design HashMap') {
    const fileName = '706DesignHashMapArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Add to Array Form of Integer') {
    const fileName = '989AddtoArrayFormofIntegerArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Unique Morse Code Words') {
    const fileName = '804UniqueMorseCodeWordsArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Toeplitz Matrix') {
    const fileName = '766ToeplitzMatrixArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Delete Columns to Make Sorted') {
    const fileName = '944DeleteColumnstoMakeSortedArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find Pivot Index') {
    const fileName = '724FindPivotIndexArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Verifying an Alien Dictionary') {
    const fileName = '953VerifyinganAlienDictionaryArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Available Captures for Rook') {
    const fileName = '999AvailableCapturesforRookArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Range Sum Query   Immutable') {
    const fileName = '303RangeSumQueryImmutableArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Distribute Candies') {
    const fileName = '575DistributeCandiesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Missing Number') {
    const fileName = '268MissingNumberArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Degree of an Array') {
    const fileName = '697DegreeofanArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Intersection of Two Arrays II') {
    const fileName = '350IntersectionofTwoArraysIIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Intersection of Two Arrays') {
    const fileName = '349IntersectionofTwoArraysArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Baseball Game') {
    const fileName = '682BaseballGameArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Teemo Attacking') {
    const fileName = '495TeemoAttackingArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Lemonade Change') {
    const fileName = '860LemonadeChangeArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Largest Number At Least Twice of Others') {
    const fileName = '747LargestNumberAtLeastTwiceofOthersArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Next Greater Element I') {
    const fileName = '496NextGreaterElementIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Image Smoother') {
    const fileName = '661ImageSmootherArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Sort Array By Parity II') {
    const fileName = '922SortArrayByParityIIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Pascal s Triangle II') {
    const fileName = '119PascalsTriangleIIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Projection Area of 3D Shapes') {
    const fileName = '883ProjectionAreaof3DShapesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Read N Characters Given Read4') {
    const fileName = '157ReadNCharactersGivenRead4ArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Minimum Index Sum of Two Lists') {
    const fileName = '599MinimumIndexSumofTwoListsArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find All Numbers Disappeared in an Array') {
    const fileName = '448FindAllNumbersDisappearedinanArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Transpose Matrix') {
    const fileName = '867TransposeMatrixArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Design HashSet') {
    const fileName = '705DesignHashSetArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Maximum Product of Three Numbers') {
    const fileName = '628MaximumProductofThreeNumbersArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Monotonic Array') {
    const fileName = '896MonotonicArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'X of a Kind in a Deck of Cards') {
    const fileName = '914XofaKindinaDeckofCardsArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Design Compressed String Iterator') {
    const fileName = '604DesignCompressedStringIteratorArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Maximum Average Subarray I') {
    const fileName = '643MaximumAverageSubarrayIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Search Insert Position') {
    const fileName = '35SearchInsertPositionArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Max Consecutive Ones') {
    const fileName = '485MaxConsecutiveOnesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Summary Ranges') {
    const fileName = '228SummaryRangesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Range Addition II') {
    const fileName = '598RangeAdditionIIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'N Repeated Element in Size 2N Array') {
    const fileName = '961NRepeatedElementinSize2NArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Island Perimeter') {
    const fileName = '463IslandPerimeterArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Sort Array By Parity') {
    const fileName = '905SortArrayByParityArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Can Place Flowers') {
    const fileName = '605CanPlaceFlowersArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Longest Continuous Increasing Subsequence') {
    const fileName =
      '674LongestContinuousIncreasingSubsequenceArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Partition Array Into Three Parts With Equal Sum') {
    const fileName =
      '1013PartitionArrayIntoThreePartsWithEqualSumArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Largest Triangle Area') {
    const fileName = '812LargestTriangleAreaArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Surface Area of 3D Shapes') {
    const fileName = '892SurfaceAreaof3DShapesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Flood Fill') {
    const fileName = '733FloodFillArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find Anagram Mappings') {
    const fileName = '760FindAnagramMappingsArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Move Zeroes') {
    const fileName = '283MoveZeroesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Plus One') {
    const fileName = '66PlusOneArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Array Partition') {
    const fileName = '561ArrayPartitionArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Remove Duplicates from Sorted Array') {
    const fileName = '26RemoveDuplicatesfromSortedArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Third Maximum Number') {
    const fileName = '414ThirdMaximumNumberArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Reshape the Matrix') {
    const fileName = '566ReshapetheMatrixArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Moving Average from Data Stream') {
    const fileName = '346MovingAveragefromDataStreamArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Longest Harmonious Subsequence') {
    const fileName = '594LongestHarmoniousSubsequenceArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Shortest Completing Word') {
    const fileName = '748ShortestCompletingWordArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Shortest Word Distance') {
    const fileName = '243ShortestWordDistanceArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find Smallest Letter Greater Than Target') {
    const fileName =
      '744FindSmallestLetterGreaterThanTargetArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Valid Mountain Array') {
    const fileName = '941ValidMountainArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Squares of a Sorted Array') {
    const fileName = '977SquaresofaSortedArrayArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Pascal s Triangle') {
    const fileName = '118PascalsTriangleArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Binary Prefix Divisible By 5') {
    const fileName = '1018BinaryPrefixDivisibleBy5ArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Fair Candy Swap') {
    const fileName = '888FairCandySwapArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Binary Search') {
    const fileName = '704BinarySearchArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'DI String Match') {
    const fileName = '942DIStringMatchArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Smallest Range I') {
    const fileName = '908SmallestRangeIArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Largest Perimeter Triangle') {
    const fileName = '976LargestPerimeterTriangleArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Find the Town Judge') {
    const fileName = '997FindtheTownJudgeArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Missing Ranges') {
    const fileName = '163MissingRangesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Valid Word Square') {
    const fileName = '422ValidWordSquareArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Remove Element') {
    const fileName = '27RemoveElementArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === '1 bit and 2 bit Characters') {
    const fileName = '7171bitand2bitCharactersArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Meeting Rooms') {
    const fileName = '252MeetingRoomsArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Best Time to Buy and Sell Stock') {
    const fileName = '121BestTimetoBuyandSellStockArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Unique Email Addresses') {
    const fileName = '929UniqueEmailAddressesArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Number of Lines To Write String') {
    const fileName = '806NumberofLinesToWriteStringArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Majority Element') {
    const fileName = '169MajorityElementArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Shortest Distance to a Character') {
    const fileName = '821ShortestDistancetoaCharacterArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Most Common Word') {
    const fileName = '819MostCommonWordArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Set Mismatch') {
    const fileName = '645SetMismatchArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Sentence Similarity') {
    const fileName = '734SentenceSimilarityArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Two Sum') {
    const fileName = '1TwoSumArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Convert Sorted Array to Binary Search Tree') {
    const fileName =
      '108ConvertSortedArraytoBinarySearchTreeArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  } else if (title === 'Contains Duplicate') {
    const fileName = '217ContainsDuplicateArrayEasyContent.js';
    setComponent(() => {
      return loadingProblemStatementData(fileName);
    });
  }
};
