export const webDevelopmentSkills = [
  'html',
  'css',
  'javascript-es6',
  'react-js',
  'responsive-design-react-js',
  'node-js',
  'express-js',
  'mongodb',
  'postgresql',
  'asynchronous-programming-in-javascript',
  'git',
  'npm-yarn',
  'problem-solving-using-javascript',
  'webpack-babel',
];

export const webDevelopmentSkillsMapping = {
  html: 'HTML',
  css: 'CSS',
  'javascript-es6': 'JavaScript (ES6+)',
  'react-js': 'React.js',
  'responsive-design-react-js': 'Responsive Design (React.js)',
  'node-js': 'Node.js',
  'express-js': 'Express.js',
  mongodb: 'MongoDB',
  postgresql: 'PostgreSQL',
  'asynchronous-programming-in-javascript':
    'Asynchronous Programming In JavaScript',
  git: 'Git',
  'npm-yarn': 'NPM & Yarn',
  'problem-solving-using-javascript': 'Problem-Solving Using JavaScript',
  'webpack-babel': 'Webpack & Babel',
};

export const webDevelopmentSkillsMappingUpper = {
  HTML: 'html',
  CSS: 'css',
  'JavaScript (ES6+)': 'javascript-es6',
  'React.js': 'react-js',
  'Responsive Design (React.js)': 'responsive-design-react-js',
  'Node.js': 'node-js',
  'Express.js': 'express-js',
  MongoDB: 'mongodb',
  PostgreSQL: 'postgresql',
  'Asynchronous Programming In JavaScript':
    'asynchronous-programming-in-javascript',
  Git: 'git',
  'NPM & Yarn': 'npm-yarn',
  'Problem-Solving Using JavaScript': 'problem-solving-using-javascript',
  'Webpack & Babel': 'webpack-babel',
};
