import {
  jsConcepts,
  ppsConcepts,
  htmlConcepts,
  cssConcepts,
  javascriptConcepts,
  reactConcepts,
  responsiveDesignConcepts,
  nodejsConcepts,
  expressConcepts,
  mongodbConcepts,
  postgresqlConcepts,
  asyncProgrammingConcepts,
  gitConcepts,
  packageManagerConcepts,
  webpackBabelConcepts,
  problemSolvingJSConcepts,
} from './docsData.js';

export const getSelectedDocSyllabus = (topicName) => {
  console.log(`topicName: ${topicName}`);
  if (topicName === 'JavaScript') {
    return jsConcepts;
  } else if (topicName === 'PROGRAMMING FOR PROBLEM SOLVING') {
    return ppsConcepts;
  } else if (topicName === 'HTML') {
    return htmlConcepts;
  } else if (topicName === 'CSS') {
    return cssConcepts;
  } else if (topicName === 'JavaScript (ES6+)') {
    return javascriptConcepts;
  } else if (topicName === 'React.js') {
    return reactConcepts;
  } else if (topicName === 'Responsive Design (React.js)') {
    return responsiveDesignConcepts;
  } else if (topicName === 'Node.js') {
    return nodejsConcepts;
  } else if (topicName === 'Express.js') {
    return expressConcepts;
  } else if (topicName === 'MongoDB') {
    return mongodbConcepts;
  } else if (topicName === 'PostgreSQL') {
    return postgresqlConcepts;
  } else if (topicName === 'Asynchronous Programming In JavaScript') {
    return asyncProgrammingConcepts;
  } else if (topicName === 'Git') {
    return gitConcepts;
  } else if (topicName === 'NPM_Yarn') {
    return packageManagerConcepts;
  } else if (topicName === 'Webpack_Babel') {
    return webpackBabelConcepts;
  } else if (topicName === 'Problem-Solving-Using-JavaScript') {
    return problemSolvingJSConcepts;
  }
};
