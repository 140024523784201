import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const extractLanguage = (node) => {
  const className = node?.properties?.className || [];
  const languageClass = className.find((cls) => cls.startsWith('language-'));
  return languageClass ? languageClass.replace('language-', '') : null;
};

export const CodeRenderer = ({ node, disablePreview, children }) => {
  const [executionResult, setExecutionResult] = useState(null);
  const [copied, setCopied] = useState(false);

  const codeText = children || ''; // Extract code text from children
  const language = extractLanguage(node); // Extract language from node properties
  const isBlockCode = language != null; // Block code check

  const executeJavaScript = (code) => {
    try {
      const func = new Function(code); // Create a new function from the code
      const result = func();
      console.log(`result function: ${result}`);
      return result === undefined ? 'No output' : String(result);
    } catch (error) {
      return `Error: ${error.message}`;
    }
  };

  const handleRunCode = (code) => {
    try {
      const result = executeJavaScript(code); // Use eval to run the code (Only for trusted inputs)
      console.log(`result: ${result}`);
      return result === undefined ? 'No output' : String(result);
    } catch (error) {
      return `Error: ${error.message}`;
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
  };

  const handleExecute = () => {
    const result = handleRunCode(codeText);
    setExecutionResult(result);
  };

  // For inline code, render without the "Copy Code" and "Run" buttons
  if (!isBlockCode) {
    return <code>{codeText}</code>;
  }

  return (
    <div style={{ position: 'relative', marginBottom: '1em' }}>
      <CopyToClipboard text={codeText} onCopy={handleCopy}>
        <button
          style={{
            position: 'absolute',
            top: '5px',
            right: '80px',
            padding: '5px',
            backgroundColor: copied ? 'green' : '#007bff',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            fontSize: '0.9em',
            zIndex: 1,
            borderRadius: '5px',
          }}
        >
          {copied ? 'Copied!' : 'Copy Code'}
        </button>
      </CopyToClipboard>
      <button
        onClick={handleExecute}
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          padding: '5px',
          backgroundColor: '#28a745',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          fontSize: '0.9em',
          zIndex: 1,
          borderRadius: '5px',
        }}
      >
        Run
      </button>
      <pre
        style={{
          backgroundColor: 'black', // Black background for code block
          color: 'white', // White text for code block
          overflowX: 'auto', // Handle horizontal overflow
        }}
      >
        <code className={`language-${language}`}>{codeText}</code>
      </pre>
      {executionResult && !disablePreview && (
        <div
          style={{
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#f8f9fa',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        >
          <strong>Output:</strong> {executionResult}
        </div>
      )}
    </div>
  );
};
