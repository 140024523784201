import React from 'react';
import { Helmet } from 'react-helmet';

const seoContent = {
  home: {
    title: 'Home | HLS Live',
    description:
      'Welcome to HLS Live - Learn and grow with interactive courses and tutorials.',
    og: {
      siteName: 'HLS Live',
      type: 'website',
      title: 'Home | HLS Live',
      description:
        'Welcome to HLS Live - Learn and grow with interactive courses and tutorials.',
      image: 'https://hlslive.in/assets/images/og-image-home.png',
      url: 'https://hlslive.in',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@hlslive',
      title: 'Home | HLS Live',
      description:
        'Welcome to HLS Live - Learn and grow with interactive courses and tutorials.',
      image: 'https://hlslive.in/assets/images/og-image-home.png',
    },
    canonical: 'https://hlslive.in',
  },
  about: {
    title: 'About Us | HLS Live',
    description:
      'Discover the story of HLS Live - Your partner in interactive learning.',
    og: {
      siteName: 'HLS Live',
      type: 'website',
      title: 'About Us | HLS Live',
      description:
        'Discover the story of HLS Live - Your partner in interactive learning.',
      image: 'https://hlslive.in/assets/images/og-image-about.png',
      url: 'https://hlslive.in/about',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@hlslive',
      title: 'About Us | HLS Live',
      description:
        'Discover the story of HLS Live - Your partner in interactive learning.',
      image: 'https://hlslive.in/assets/images/og-image-about.png',
    },
    canonical: 'https://hlslive.in/about',
  },
  courses: {
    title: 'Courses | HLS Live',
    description:
      'Explore our wide range of courses designed to boost your skills.',
    og: {
      siteName: 'HLS Live',
      type: 'website',
      title: 'Courses | HLS Live',
      description:
        'Explore our wide range of courses designed to boost your skills.',
      image: 'https://hlslive.in/assets/images/og-image-courses.png',
      url: 'https://hlslive.in/courses',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@hlslive',
      title: 'Courses | HLS Live',
      description:
        'Explore our wide range of courses designed to boost your skills.',
      image: 'https://hlslive.in/assets/images/og-image-courses.png',
    },
    canonical: 'https://hlslive.in/courses',
  },
  contact: {
    title: 'Contact Us | HLS Live',
    description: 'Get in touch with the HLS Live team for queries and support.',
    og: {
      siteName: 'HLS Live',
      type: 'website',
      title: 'Contact Us | HLS Live',
      description:
        'Get in touch with the HLS Live team for queries and support.',
      image: 'https://hlslive.in/assets/images/og-image-contact.png',
      url: 'https://hlslive.in/contact',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@hlslive',
      title: 'Contact Us | HLS Live',
      description:
        'Get in touch with the HLS Live team for queries and support.',
      image: 'https://hlslive.in/assets/images/og-image-contact.png',
    },
    canonical: 'https://hlslive.in/contact',
  },
};

const structuredData = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name: 'Learn Coding with HLS',
  description:
    'Join millions of learners mastering coding, web development, and problem-solving with Happy Learning Solutions (HLS).',
  url: 'https://hlslive.in',
  image: 'https://hlslive.in/logo.png',
  publisher: {
    '@type': 'Organization',
    name: 'Happy Learning Solutions',
    logo: 'https://hlslive.in/logo.png',
  },
};

const SEOHomePage = ({ page }) => {
  const seo = seoContent[page];

  if (!seo) {
    console.error(`SEO content for page "${page}" not found`);
    return null;
  }

  return (
    <Helmet>
      {/* Title */}
      <title>{seo.title}</title>

      {/* Meta Description */}
      <meta name="description" content={seo.description} />

      {/* Open Graph Meta Tags */}
      <meta property="og:site_name" content={seo.og.siteName} />
      <meta property="og:type" content={seo.og.type} />
      <meta property="og:title" content={seo.og.title} />
      <meta property="og:description" content={seo.og.description} />
      <meta property="og:image" content={seo.og.image} />
      <meta property="og:url" content={seo.og.url} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content={seo.twitter.card} />
      <meta name="twitter:site" content={seo.twitter.site} />
      <meta name="twitter:title" content={seo.twitter.title} />
      <meta name="twitter:description" content={seo.twitter.description} />
      <meta name="twitter:image" content={seo.twitter.image} />

      {/* Canonical URL */}
      <link rel="canonical" href={seo.canonical} />

      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default SEOHomePage;
